import React from 'react'
import {useTranslation} from 'react-i18next'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {Box, IconButton} from '@mui/material'
import {FieldArrayRenderProps} from 'formik'
import {FormTextField as TextField, Typography} from 'ui-lib'

import {ServiceGroupItem} from '../../CompanyForm/types'
import OrderControls from '../OrderControls'

import {ServiceItem} from 'pages/Company/types'

import useGroupCardStyles from './styles'

interface GroupCardProps {
  service: ServiceItem
  index: number
  onDeleteGroup: (index: number) => void
  arrayHelpers: FieldArrayRenderProps
  values: ServiceGroupItem[]
}

const GroupCard: React.FC<GroupCardProps> = ({service, index, onDeleteGroup, arrayHelpers, values}) => {
  const classes = useGroupCardStyles()
  const {t} = useTranslation()
  const sequenceIndex = index + 1

  const handleMoveToTopSequence = () => {
    if (sequenceIndex !== 1) {
      const indexTo = index - 1

      arrayHelpers.move(index, indexTo)
    }
  }

  const handleMoveToBottomSequence = () => {
    if (index < values.length - 1) {
      const indexTo = index + 1

      arrayHelpers.move(index, indexTo)
    }
  }

  return (
    <Box className={classes.container} data-test={`group-card-${service.id}`}>
      <OrderControls
        handleMoveToTopSequence={handleMoveToTopSequence}
        handleMoveToBottomSequence={handleMoveToBottomSequence}
        isInGroup={false}
        serviceIndex={index}
        valuesLength={values.length}
      />

      <Box className={classes.cardWrapper}>
        <Box className={classes.switchBox}>
          <Typography variant="h2">{service?.originalName || t('create-company.group-name-placeholder')}</Typography>

          <IconButton
            onClick={e => {
              e.stopPropagation()
              onDeleteGroup(index)
            }}
            className={classes.icon}
            aria-label="delete"
            data-test="delete-group"
            component="label"
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>

        <TextField
          name={`services.${index}.name`}
          label={t('create-company.group-name')}
          inputProps={{
            maxLength: 256,
            'data-test': 'name',
          }}
          helperText={t('validation.required')}
        />

        <TextField
          name={`services.${index}.englishName`}
          label={t('create-company.group-english-name')}
          inputProps={{maxLength: 256, 'data-test': 'english-name'}}
          helperText={t('validation.required')}
        />
      </Box>
    </Box>
  )
}

export default GroupCard
