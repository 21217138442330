import React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {Provider} from 'react-redux'

import {StyledEngineProvider} from '@mui/material/styles'
import {SnackbarProvider} from 'notistack'
import {ErrorBoundary, SystemErrorView, ThemeProvider} from 'ui-lib'

import MSAuth from 'containers/MSAuth'
import {AppRouter} from 'routes/Router'
import store from 'store'

const App = () => {
  return (
    <HelmetProvider>
      <ErrorBoundary fallback={<SystemErrorView />}>
        <Provider store={store}>
          <MSAuth>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme="light">
                <SnackbarProvider
                  preventDuplicate
                  autoHideDuration={5000}
                  maxSnack={3}
                  hideIconVariant
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <AppRouter />
                </SnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </MSAuth>
        </Provider>
      </ErrorBoundary>
    </HelmetProvider>
  )
}

export default App
