import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import {Box, Button} from '@mui/material'
import clsx from 'clsx'

import useToggle from 'hooks/useToggle'
import {ServiceItem} from 'pages/Company/types'

import DialogWindow from './DialogWindow'
import {useDialogSelectStyles} from './styles'

export interface SelectInfo {
  title: string
  description: string
  headerTitle: string
  inputName: string
  inputPlaceholder: string
  selectPlaceholder?: string
}

interface SelectServiceProps {
  selectInfo: SelectInfo
  className?: string
  values?: ServiceItem[]
  disabled?: boolean
  onChange: (value?: ServiceItem[]) => void
  containerClassName?: string
  selectedDisabledIds?: number[]
}

const DialogSelectService: React.FC<SelectServiceProps> = ({
  values,
  selectInfo,
  onChange,
  className,
  containerClassName,
  selectedDisabledIds,
  disabled,
}) => {
  const [isDialogVisible, showDialog, closeDialog] = useToggle()
  const classes = useDialogSelectStyles()
  const history = useHistory()
  const params = new URLSearchParams(window.location.search)
  const openByDefault = params.get('open-by-default')

  const handleSubmit = (value?: ServiceItem[]) => {
    if (value) {
      onChange([...value])
    }
    closeDialog()
  }

  useEffect(() => {
    if (openByDefault) {
      showDialog()
      history.replace({search: ''})
    }
  }, [])

  return (
    <Box className={clsx(classes.container, containerClassName)}>
      <Box className={clsx(className, disabled && classes.disabled)}>
        {!values?.length && (
          <Button fullWidth onClick={showDialog} className={classes.button} data-test="select-service-name-btn">
            <AddIcon />
            <Box>{selectInfo.selectPlaceholder}</Box>
          </Button>
        )}

        <DialogWindow
          selectedDisabledIds={selectedDisabledIds}
          selectInfo={selectInfo}
          open={isDialogVisible}
          onClose={closeDialog}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  )
}

export default DialogSelectService
