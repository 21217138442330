import {addYears} from 'date-fns'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isFuture from 'date-fns/isFuture'
import isSameDay from 'date-fns/isSameDay'

export const DATE_FORMAT = 'dd/MM/yyyy'
export const DATE_DASH_FORMAT = 'yyyy-MM-dd'

export const futureDateInAYear = addYears(new Date(), 1)

type FormatType = Parameters<typeof format>
type FormatStringType = FormatType[1]
type OptionsType = FormatType[2]

export const formatDate = (
  dateOrString: Date | string,
  formatString: FormatStringType = DATE_FORMAT,
  options?: OptionsType,
) => {
  const date = typeof dateOrString === 'string' ? new Date(dateOrString) : dateOrString

  try {
    return format(date, formatString, options)
  } catch {
    return '–'
  }
}

export const isSameDayOrAfter = (date: Date, dateToCompare: Date) => {
  return isSameDay(date, dateToCompare) || isAfter(date, dateToCompare)
}

export const isTodayOrFuture = (date: Date) => {
  return isSameDay(date, new Date()) || isFuture(date)
}
