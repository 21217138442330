import get from 'lodash/get'

import {Errors} from 'constants/errors'
import {useErrors} from 'hooks/useErrors'
import {CompanyServicesOutDto, useUpdateAdminCompanyMutation} from 'store/requests'

const EditCompanySettings = () => {
  const handleErrors = useErrors()
  const [editCompanySettings, {isLoading}] = useUpdateAdminCompanyMutation()

  const handleCreateCompanySettings = async (values: CompanyServicesOutDto) => {
    try {
      await editCompanySettings({...values, id: values.companyId.toString()}).unwrap()
    } catch (error) {
      const errorCode = get(error, 'data.code') as unknown as Errors

      handleErrors(errorCode, 'notifications.bad-request')
    }
  }

  return [handleCreateCompanySettings, {isLoading}] as const
}

export default EditCompanySettings
