import {omitBy} from 'lodash'

export function requestFormDataFileAdapter<T>(data: T): FormData {
  const formData = new FormData()

  const excludeKeys = ['services', 'serviceFromGroup', 'hrs', 'receivingMethods']

  const values = omitBy(data as any, (value, key) => !value || excludeKeys.includes(key)) as T

  // eslint-disable-next-line no-restricted-syntax
  for (const key in values) {
    // eslint-disable-next-line no-prototype-builtins
    if (values?.hasOwnProperty(key)) {
      const value = values[key]
      if (Array.isArray(value) && value.length) {
        value.forEach(item => {
          if (item instanceof Object && 'file' in item && 'id' in item) {
            formData.append(key, item.file)
          }
        })
      }
    }
  }

  return formData
}
