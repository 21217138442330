import {makeStyles} from '@mui/styles'
import {colors, commonDimensions} from 'ui-lib'

interface StylesProps {
  hasSelectedItems?: boolean
}

const useRequestFiltersFormStyles = makeStyles<never, StylesProps>(() => ({
  form: {
    width: 368,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxSizing: 'border-box',
  },
  formWrapper: {
    position: 'relative',
    marginBottom: 16,

    '&:before': {
      position: 'absolute',
      content: '""',
      top: -1,
      right: 0,
      left: 0,
      width: 320,
      height: 16,
      margin: '0 auto',
      backgroundImage: 'linear-gradient(bottom, transparent 0%, white 100%)',
      zIndex: 2,
    },

    '&:after': {
      position: 'absolute',
      content: '""',
      bottom: -1,
      right: 0,
      left: 0,
      width: 320,
      height: 16,
      margin: '0 auto',
      backgroundImage: 'linear-gradient(top, transparent, white 100%)',
      zIndex: 2,
    },
  },
  formContainer: {
    transition: 'height 0.175s cubic-bezier(0, 0, 0.2, 1) 0.075s',
    height: props => `calc(100vh -
     ${commonDimensions.headerHeight} -
     ${commonDimensions.pageHeaderHeight} -
     ${commonDimensions.mainTopMargin} -
     ${commonDimensions.mainBottomMargin} -
     ${commonDimensions.borderWidth} -
     ${commonDimensions.tabs} -
     ${commonDimensions.filterHeader} -
     ${commonDimensions.filterFooter} -
     ${commonDimensions.filterContainerPaddings} -
     ${props.hasSelectedItems ? commonDimensions.actionPanelCompensation : '0px'}
    )`,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    padding: '16px 24px',
  },
  formFooter: {
    padding: '16px 24px 24px 24px',
  },
  submitButton: {
    marginTop: 'auto',
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 48,
    padding: '8px 24px 0 24px',
    marginBottom: 16,
  },
  formHeaderTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
    margin: 0,
  },
  radioLabel: {
    '& .MuiTypography-root': {
      fontSize: 16,
      fontWeight: 'normal',
    },
  },
}))

export default useRequestFiltersFormStyles
