import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {Header, DialogDefault, Typography} from 'ui-lib'

import {msal} from 'containers/MSAuth/MSAuth'
import useToggle from 'hooks/useToggle'
import routes from 'routes'
import {getUserNameSelector} from 'store/account'

const msalLogout = msal.logout

const HeaderWrapper = () => {
  const userName = useSelector(getUserNameSelector)
  const [isOpenLogoutModal, openLogoutWindow, closeLogoutWindow] = useToggle()
  const {t} = useTranslation()

  return (
    <>
      <Header
        userName={userName}
        logoLink={routes.main}
        onLogout={msalLogout}
        onOpenLogoutWindow={openLogoutWindow}
        headerTitle={t('header.title')}
      />
      <DialogDefault
        dialogPaperDataTest="logout-dialog"
        title={t('logout-modal.title')}
        open={isOpenLogoutModal}
        handleClose={closeLogoutWindow}
        activeActionText={t('logout-modal.logout')}
        cancelActionText={t('logout-modal.cancel')}
        handleActiveAction={msalLogout}
        activeActionBtnType="default-primary-yellow"
        cancelActionBtnType="default-secondary-yellow"
      >
        <Typography data-test="dialog-sub-title" variant="h2">
          {t('logout-modal.sub-title')}
        </Typography>
        <Typography data-test="dialog-text" variant="subtitle2">
          {t('logout-modal.description-re-login')}
        </Typography>
        <Typography data-test="dialog-text" variant="subtitle2" style={{marginBottom: 8}}>
          {t('logout-modal.description-confirm')}
        </Typography>
      </DialogDefault>
    </>
  )
}

export default HeaderWrapper
