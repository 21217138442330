import * as Yup from 'yup'

import {ServicesEnum} from 'constants/services'

const serviceItemSchema: any = Yup.lazy(() =>
  Yup.object().shape({
    isActive: Yup.boolean().nullable(),
    name: Yup.string().required('validation.required'),
    englishName: Yup.string().required('validation.required'),
    serviceFromGroup: Yup.array().of(serviceItemSchema),
    hrServiceType: Yup.string().max(256).nullable(),
    templateFile: Yup.array()
      .nullable()
      .when(['isActive', 'hrServiceType', 'isEnableEReferenceReceivingMethod'], {
        is: (isActive: boolean, hrServiceType: ServicesEnum, isEnableEReferenceReceivingMethod: boolean) =>
          hrServiceType === ServicesEnum.REFERENCES && isActive && isEnableEReferenceReceivingMethod,
        then: schema => schema.min(1).required('validation.required'),
        otherwise: schema => schema,
      }),
    stampFile: Yup.array().nullable(),
    headerFirstPageFile: Yup.array()
      .nullable()
      .when(['isActive', 'hrServiceType', 'isEnableEReferenceReceivingMethod'], {
        is: (isActive: boolean, hrServiceType: ServicesEnum, isEnableEReferenceReceivingMethod: boolean) =>
          hrServiceType === ServicesEnum.REFERENCES && isActive && isEnableEReferenceReceivingMethod,
        then: schema => schema.min(1).required('validation.required'),
        otherwise: schema => schema,
      }),
    headerNextPageFile: Yup.array()
      .nullable()
      .when(['isActive', 'hrServiceType', 'isEnableEReferenceReceivingMethod'], {
        is: (isActive: boolean, hrServiceType: ServicesEnum, isEnableEReferenceReceivingMethod: boolean) =>
          hrServiceType === ServicesEnum.REFERENCES && isActive && isEnableEReferenceReceivingMethod,
        then: schema => schema.min(1).required('validation.required'),
        otherwise: schema => schema,
      }),
    footerFile: Yup.array().nullable(),
  }),
)

export const validationFormSchema = Yup.object().shape({
  logo: Yup.array().nullable().min(1).required('validation.required'),
  services: Yup.array().of(serviceItemSchema).required('validation.required'),
})
