import React from 'react'
import {useTranslation} from 'react-i18next'

import {Box} from '@mui/material'
import cond from 'lodash/cond'
import {ActionProps, Button, NoSearchData} from 'ui-lib'

interface ButtonProps extends ActionProps {
  startIcon: React.ReactNode
}

interface NoSearchDataWithButtonProps {
  withFilters?: boolean
  buttonProps?: ButtonProps
}

const NoSearchDataWithButton: React.FC<NoSearchDataWithButtonProps> = ({withFilters, buttonProps}) => {
  const {t} = useTranslation()

  const description = cond([
    [() => !!buttonProps, () => t('no-search-data.description-for-my')],
    [() => !!withFilters, () => t('no-search-data.description-with-filters')],
    [() => !withFilters, () => t('no-search-data.description-with-no-filters')],
  ])()

  return (
    <NoSearchData title={t('no-search-data.title')} subtitles={[description]}>
      {!!buttonProps && (
        <Box sx={{marginTop: '10px'}}>
          <Button
            disabled={buttonProps.disabled}
            startIcon={buttonProps.startIcon}
            type={buttonProps.type || 'default'}
            onClick={buttonProps.onClick}
            data-test={buttonProps.dataTest}
            htmlType={buttonProps.htmlType}
          >
            {buttonProps.label}
          </Button>
        </Box>
      )}
    </NoSearchData>
  )
}

export default NoSearchDataWithButton
