import castArray from 'lodash/castArray'
import get from 'lodash/get'

import {IOption} from 'types'

import {RequestFiltersFormInitialState} from './components/RequestFiltersForm/RequestFiltersForm'
import {RequestsListPageParams} from './types'

export const filtersFormInitialStataAdapter = (queryParams: RequestsListPageParams): RequestFiltersFormInitialState => {
  return {
    hrIds: castArray(queryParams.hrIds?.map(hrId => +hrId) ?? []),
    companyIds: castArray(queryParams.companyIds?.map(companyId => +companyId) ?? []),
    entityType: queryParams.entityType,
  }
}

export const hrOptionsFilterFunc = (options: IOption[], state: {inputValue: string}) => {
  const {inputValue = ''} = state
  return options
    .filter(option => {
      const label = get(option, 'label', '') as string
      const email = get(option, 'email', '') as string
      return (
        label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
        email.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      )
    })
    .sort((a, b) => {
      const labelA = get(a, 'label', '') as string
      const emailA = get(a, 'email', '') as string
      const labelB = get(b, 'label', '') as string
      const emailB = get(b, 'email', '') as string
      const indexInLabelA = labelA.toLowerCase().indexOf(inputValue.toLowerCase())
      const indexInEmailA = emailA.toLowerCase().indexOf(inputValue.toLowerCase())
      const indexInLabelB = labelB.toLowerCase().indexOf(inputValue.toLowerCase())
      const indexInEmailB = emailB.toLowerCase().indexOf(inputValue.toLowerCase())

      return (
        Math.min(indexInLabelA > -1 ? indexInLabelA : Infinity, indexInEmailA > -1 ? indexInEmailA : Infinity) -
        Math.min(indexInLabelB > -1 ? indexInLabelB : Infinity, indexInEmailB > -1 ? indexInEmailB : Infinity)
      )
    })
}
