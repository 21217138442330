import type {BaseQueryFn} from '@reduxjs/toolkit/query'
import type {AxiosRequestConfig, AxiosError} from 'axios'

import axios from 'API/axiosSetting'

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string
      method?: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers?: AxiosRequestConfig['headers']
      responseType?: AxiosRequestConfig['responseType']
    },
    unknown,
    unknown
  > =>
  async ({url, method, data, params, headers, responseType}) => {
    try {
      const result = await axios({url, method, data, params, headers, responseType})
      return {data: result.data}
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export default axiosBaseQuery
