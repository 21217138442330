import React from 'react'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {Box} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'

import useToggle from 'hooks/useToggle'

import MenuItem from './MenuItem'
import useSideBarMenuStyles from './styles'

interface CollapsedMenuItemProps {
  name: string
}

const CollapsedMenuItem: React.FC<CollapsedMenuItemProps> = ({name, children}) => {
  const classes = useSideBarMenuStyles()
  const [isMenuItemOpened, , , toggleCollapse] = useToggle()

  return (
    <Box className={classes.collapseContainer}>
      <MenuItem className={classes.collapsedItem} name={name} onClick={toggleCollapse} data-test="collapse-item" />
      <Box onClick={toggleCollapse} className={classes.collapseArrow}>
        {isMenuItemOpened ? <ExpandLess /> : <ExpandMore />}
      </Box>

      <Collapse in={isMenuItemOpened} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </Box>
  )
}

export default CollapsedMenuItem
