import {FilterOptionDTO} from 'types'

export const getAdaptedFilterOptions = (filterOptions?: Record<string, FilterOptionDTO[]>) => {
  const filterOptionsEntries = Object.entries(filterOptions || {})
  return filterOptionsEntries.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value.map(({id, name, ...rest}) => ({
        label: name,
        value: id,
        ...rest,
      })),
    }
  }, {})
}
