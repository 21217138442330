import {makeStyles} from '@mui/styles'

const useServiceFormStyles = makeStyles(() => ({
  formSection: {
    padding: 50,
  },

  attributeFormSection: {
    display: 'grid',
    margin: '0 auto',
    maxWidth: '60%',
    width: '100%',
    gap: 16,
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
      'name'
      'englishName'
      'radioGroup'
      'optionFiled1'
      'optionFiled2'
      'adField'
      'logo'
    `,
  },

  name: {
    gridArea: 'name',
  },
  englishName: {
    gridArea: 'englishName',
  },
  radioGroup: {
    gridArea: 'radioGroup',
    display: 'flex',
    flexDirection: 'row',
    gap: '30%',
  },
  hrServiceType: {
    gridArea: 'optionFiled1',
  },
  link: {
    gridArea: 'optionFiled1',
  },
  englishLink: {
    gridArea: 'optionFiled2',
  },
  adField: {
    gridArea: 'adField',
  },

  logo: {
    gridArea: 'logo',
  },

  radioLabel: {
    '& .MuiTypography-root': {
      fontSize: 16,
      fontWeight: 'normal',
    },
  },

  switchControlLabel: {
    fontWeight: 400,
  },
}))

export default useServiceFormStyles
