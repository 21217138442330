import React from 'react'
import {useTranslation} from 'react-i18next'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {Box, IconButton} from '@mui/material'
import clsx from 'clsx'
import {Typography} from 'ui-lib'

import {Unit} from 'types'

import {useCompanyOptionsStyles} from './styles'

export interface PositionOptionProps extends Unit {
  onClick?: () => void
  selected?: boolean
  onIconClick: (id: number) => void
  disabledHover?: boolean
  disabled?: boolean
}

const CompanySelectedOption: React.FC<PositionOptionProps> = ({
  id,
  companyName,
  name,
  onClick,
  selected,
  disabledHover,
  onIconClick,
  disabled,
}) => {
  const classes = useCompanyOptionsStyles()
  const {t} = useTranslation()

  return (
    <Box
      data-test="company-btn"
      onClick={onClick}
      className={clsx(classes.container, selected && classes.selected, disabledHover && classes.disabledHover)}
    >
      <Box>
        <Box className={classes.labelBox}>
          <Typography variant="subtitle2" className={classes.label}>
            {t('create-company.label-id')}
          </Typography>
          <Typography data-test="company-id" variant="subtitle2" className={classes.text}>
            {id ?? '-'}
          </Typography>
        </Box>

        <Box>
          <Typography variant="subtitle2" className={classes.label}>
            {t('create-company.label-country')}
          </Typography>
          <Typography data-test="company-country" variant="subtitle2" className={classes.text}>
            Україна
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box className={classes.labelBox}>
          <Typography variant="subtitle2" className={classes.label}>
            {t('create-company.label-name')}
          </Typography>
          <Typography data-test="company-name" variant="subtitle2" className={classes.text}>
            {(companyName || name) ?? '-'}
          </Typography>
        </Box>
      </Box>

      {!disabled && (
        <IconButton
          onClick={e => {
            e.stopPropagation()
            onIconClick(id)
          }}
          className={classes.icon}
          aria-label="delete"
          data-test="delete-company"
          component="label"
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </Box>
  )
}

export default CompanySelectedOption
