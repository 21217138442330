import React, {useState} from 'react'
import LinesEllipsis from 'react-lines-ellipsis'

import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

const OverflowTip = ({text, tooltipProps, textProps, 'data-test': dataTest, maxLine}) => {
  const [isClamped, setIsClamped] = useState(false)
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  const {style: textPropsStyle = {}} = textProps

  return (
    <Tooltip title={text} disableHoverListener={!isClamped} {...tooltipProps}>
      <div
        style={{
          width: '100%',
          overflowWrap: 'anywhere',
          ...textPropsStyle,
        }}
      >
        <ResponsiveEllipsis
          {...(dataTest && {'data-test': dataTest, value: text})}
          onReflow={({clamped}) => {
            if (clamped !== isClamped) setIsClamped(clamped)
          }}
          text={text || ''}
          maxLine={maxLine}
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
    </Tooltip>
  )
}

OverflowTip.defaultProps = {
  text: '',
  tooltipProps: {},
  textProps: {},
  'data-test': '',
  maxLine: 1,
}

OverflowTip.propTypes = {
  text: PropTypes.string,
  maxLine: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltipProps: PropTypes.shape({
    placement: PropTypes.string,
  }),
  textProps: PropTypes.shape({
    style: PropTypes.any, // eslint-disable-line
  }),
  'data-test': PropTypes.string,
}

export default OverflowTip
