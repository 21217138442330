import {makeStyles} from '@mui/styles'
import {colors, commonDimensions} from 'ui-lib'

interface StylesProps {
  hasSelectedItems?: boolean
  canSeeSomeTab?: boolean
}

export const useTableStyles = makeStyles<never, StylesProps>(() => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  headerTitle: {
    flex: 1,
  },
  createLink: {
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    color: colors.primaryLight,
  },
  tableRow: {
    cursor: 'pointer',
    // link styles
    '&:hover $idCell': {
      textDecoration: 'underline',
    },
    '& a': {
      textDecoration: 'none',
      color: colors.primaryLight,
      fontSize: 16,
    },
    '&:hover': {
      '& a': {
        textDecoration: 'underline',
      },
    },
  },
  isArchived: {
    '& .MuiTableCell-root': {
      '&:not($idCell)': {
        color: colors.gray350,
      },
    },
  },
  boldCell: {
    fontWeight: 'bold',
  },
  tableWrapper: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  table: {
    transition: 'max-height 0.175s cubic-bezier(0, 0, 0.2, 1) 0.075s',
    maxHeight: props => `calc(100vh -
     ${commonDimensions.headerHeight} -
     ${commonDimensions.pageHeaderHeight} -
     ${commonDimensions.mainTopMargin} -
     ${commonDimensions.mainBottomMargin} -
     ${commonDimensions.borderWidth} -
     ${commonDimensions.tabs} -
     ${props.canSeeSomeTab ? commonDimensions.tabs : '0px'} -
     ${commonDimensions.borderWidth} -
     ${props.hasSelectedItems ? commonDimensions.actionPanelCompensation : '0px'}
    )`,
  },
  forRevisionWrapper: {
    width: 180,
  },
  colBlock: {
    '& div + div': {
      color: colors.gray350,
    },
  },
  approver: {
    color: colors.gray350,
  },
  idCell: {
    textDecoration: 'none',
    color: colors.primaryLight,
    fontSize: 16,
  },
  addButton: {
    marginRight: 24,
  },
  external: {
    '& th:not(:first-of-type)': {
      color: colors.gray350,
    },
    '& th:last-child > div': {
      minWidth: 160,
    },
  },
  statusBox: {
    display: 'flex',
    alignItems: 'center',
  },
  externalIcon: {
    marginLeft: 'auto',

    '& svg': {
      marginLeft: 2,
    },
  },
}))
