import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

interface StylesProps {
  isActive?: boolean
}
export const useServicesAccessStyles = makeStyles<never, StylesProps>(() => ({
  centeredText: {
    textAlign: 'center',
  },
  iconWrapper: {
    display: 'inline-flex',
    padding: 4,
    borderRadius: '50%',
    transition: 'background-color 0.3s',

    '&:hover': {
      backgroundColor: ({isActive}) => isActive && colors.dtekLightYellow,

      '& svg': {
        color: ({isActive}) => isActive && colors.black,
      },
    },
  },
}))
