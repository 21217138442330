import React from 'react'

import {useSnackbar as useDefaultSnackbar, OptionsObject, VariantType, SnackbarKey} from 'notistack'
import {NotificationBanner} from 'ui-lib'

export const useSnackbar = () => {
  const {enqueueSnackbar} = useDefaultSnackbar()

  return (message: string, options?: OptionsObject & Partial<{variant: VariantType}>) => {
    const variant = options?.variant ?? 'default'
    enqueueSnackbar(message, {
      ...options,
      persist: variant === 'error',
      content: (key: SnackbarKey) => {
        return <NotificationBanner id={key} variant={variant} message={message} />
      },
    })
  }
}
