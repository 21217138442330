import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

const useGroupCardStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    padding: '24px 36px 24px 50px',
    borderRadius: '4px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: colors.white,
    position: 'relative',
  },
  orderContainer: {
    width: '35px',
    height: '100%',
    background: colors.gray60,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  cardWrapper: {
    display: 'grid',
    rowGap: '16px',
  },
  switchBox: {
    display: 'flex',
    alignItems: 'baseline',
  },
  companyControlsRow: {
    display: 'grid',
    gap: 16,
    '& + &': {
      borderTop: `1px solid ${colors.gray60}`,
      marginTop: 25,
      paddingTop: 25,
    },
  },
  companyControlsCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 24,
    maxWidth: 'calc(50% - 37.5px)',
  },
  icon: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
}))

export default useGroupCardStyles
