import React, {Suspense} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import PropTypes from 'prop-types'
import {Loader} from 'ui-lib'

import {loadUserData} from 'store/account'

import MsalAuth, {ACCESS_TOKEN_NAME} from './MsalAuth'

const msal = new MsalAuth()

const AuthContainer = () => {
  const dispatch = useDispatch()
  return React.lazy(
    () =>
      new Promise(resolve => {
        const handleLoginErrors = errObj => {
          console.log('login error', errObj)

          return resolve(import('./AuthenticatedApp'))
        }

        const setUserTokenAndRenderApp = ({accessToken}) => {
          localStorage.setItem(ACCESS_TOKEN_NAME, accessToken)

          dispatch(loadUserData())
            .then(data => {
              if (data.error) return handleLoginErrors(data.payload)

              return resolve(import('./AuthenticatedApp'))
            })
            .catch(err => {
              console.error('load user data Error', err)
              msal.logout()
            })
        }

        msal
          .getAccessToken()
          .then(setUserTokenAndRenderApp)
          .catch(({errorCode = null}) => {
            if (msal.requiresInteraction(errorCode)) {
              msal.acquireTokenRedirect()
              return
            }
            if (msal.isUserLoginError(errorCode)) {
              msal.redirectToLogin()
            }
          })
      }),
  )
}

const MSAuth = props => {
  const AuthAction = AuthContainer(props)
  const {t} = useTranslation()

  return (
    <Suspense fallback={<Loader title={t('loader')} />}>
      <AuthAction>{props.children}</AuthAction>
    </Suspense>
  )
}

MSAuth.propTypes = {
  children: PropTypes.any, // eslint-disable-line
}

export {msal}
export default MSAuth
