import {Id, PaginationRequest, PaginationResponse} from '../types'

import {DefaultFile} from 'components/UploadingFile/UploadingFile'
import {ServicesEnum} from 'constants/services'
import {ServiceFormValues} from 'pages/Service/ServiceForm/types'

import {api} from './api'
import {cacher} from './rtkQueryCacheUtils'

interface AdGroupOutDto {
  query: string
}

export interface AdGroupItem {
  id: string
  name: string
}

interface AdGroupInDto extends AdGroupItem {}

export interface GetServiceInDto extends Omit<ServiceFormValues, 'logo' | 'adGroups'> {
  adGroups?: AdGroupItem[]
  logo: DefaultFile
}

export interface ServiceItem {
  hrServiceType: ServicesEnum
  id: number
  name: string
  englishName: string
  logo: DefaultFile
  updatedAt: string
  delete?: string
}

export type GetRequestListInDto = PaginationResponse<ServiceItem>

export interface GetRequestListOutDto extends PaginationRequest<''> {}

const resource = 'workflow/admin'

export const servicesApi = api.injectEndpoints({
  endpoints: build => ({
    validateAdGroup: build.query<AdGroupInDto, AdGroupOutDto>({
      query: params => ({url: 'workflow/search/ad-group', params, method: 'GET'}),
      providesTags: [{type: 'validateAdGroup'}],
    }),

    createService: build.mutation<{id: Id}, FormData>({
      query: data => ({
        url: `${resource}/service`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
        data,
        formData: true,
      }),
      invalidatesTags: () => [{type: 'service', id: 'LIST'}],
    }),

    editService: build.mutation<{id: Id}, FormData>({
      query: data => ({
        url: `${resource}/service`,
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
        data,
        formData: true,
      }),
      invalidatesTags: () => [{type: 'service', id: 'LIST'}, {type: 'service'}],
    }),

    getService: build.query<GetServiceInDto, {id: Id}>({
      query: ({id}) => ({
        url: `${resource}/service/${id}`,
        method: 'GET',
      }),
      providesTags: [{type: 'service'}],
    }),

    getServicesList: build.query<GetRequestListInDto, GetRequestListOutDto>({
      query: params => ({
        url: `${resource}/services`,
        params,
        method: 'GET',
      }),
      providesTags: cacher.providesNestedList('service'),
    }),

    deleteService: build.mutation<{id: Id}, {id: Id}>({
      query: ({id}) => ({
        url: `${resource}/service/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{type: 'service', id: 'LIST'}],
    }),
  }),
})

export const {
  useLazyValidateAdGroupQuery,
  useCreateServiceMutation,
  useEditServiceMutation,
  useGetServiceQuery,
  useGetServicesListQuery,
  useDeleteServiceMutation,
} = servicesApi
