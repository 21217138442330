import isValid from 'date-fns/isValid'
import __ from 'lodash/fp/__'
import includes from 'lodash/fp/includes'
import isNaN from 'lodash/fp/isNaN'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {RequestsListPageParams} from 'types'

type TypeValue = {
  value: string | number
}

type OrderType = number

export const getCompaniesValidationConfig = (options: {companies?: TypeValue[]}, order: OrderType) => {
  return {
    order,
    validationFn: (paramValue = '') => {
      const companiesOptions = get(options, 'companies', [])
      const validValue = paramValue
        .split(',')
        .filter((v: string) => companiesOptions.find(({value}) => value.toString() === v))
      return !isEmpty(validValue)
    },
    updateFunction: (paramValue = '') => {
      const companiesOptions = get(options, 'companies', [])
      return paramValue.split(',').filter((v: string) => companiesOptions.find(({value}) => value.toString() === v))
    },
  }
}

export const getHrsValidationConfig = (options: {hrs?: TypeValue[]}, order: OrderType) => {
  return {
    order,
    validationFn: (paramValue = '') => {
      const hrOptions = get(options, 'hrs', [])
      const validValue = paramValue
        .split(',')
        .filter((v: string) => hrOptions.find(({value}) => value.toString() === v))
      return !isEmpty(validValue)
    },
    updateFunction: (paramValue = '') => {
      const hrOptions = get(options, 'hrs', [])
      return paramValue.split(',').filter((v: string) => hrOptions.find(({value}) => value.toString() === v))
    },
  }
}

export const getDateValidationConfig = (order: OrderType) => {
  return {
    order,
    validationFn: (paramValue: string, queryParams: RequestsListPageParams, dependencies?: object) => {
      const validate = () => isValid(new Date(paramValue))
      if (!dependencies) {
        return validate()
      }
      const isValidByDependencies = Object.entries(dependencies).every(([dependencyKey, dependencyValue]) => {
        return get(queryParams, dependencyKey) === dependencyValue
      })
      if (isValidByDependencies) {
        return validate()
      }
      return false
    },
  }
}

export const getLimitOffsetValidationConfig = (order: OrderType) => {
  return {
    order,
    validationFn: (paramValue: string | number, queryParams: RequestsListPageParams, dependencies?: object) => {
      const validate = () => !isNaN(Number(paramValue))
      if (!dependencies) {
        return validate()
      }
      const isValidByDependencies = Object.entries(dependencies).every(([dependencyKey, dependencyValue]) => {
        return get(queryParams, dependencyKey) === dependencyValue
      })
      if (isValidByDependencies) {
        return validate()
      }
      return false
    },
  }
}

export const getSortingFieldValidationConfig = (fieldArray: string[], order: OrderType) => {
  return {
    order,
    validationFn: includes(__, fieldArray),
  }
}

export const getSortingOrderValidationConfig = (order: OrderType) => {
  return {
    order,
    validationFn: (paramValue: string, queryParams: RequestsListPageParams, dependencies?: object) => {
      if (!dependencies) {
        return ['asc', 'desc'].includes(paramValue)
      }
      const isValidByDependencies = Object.entries(dependencies).every(([dependencyKey, dependencyValue]) => {
        return get(queryParams, dependencyKey) === dependencyValue
      })
      if (isValidByDependencies) {
        return ['asc', 'desc'].includes(paramValue)
      }
      return false
    },
  }
}

export const getEntityTypeValidationConfig = (fieldArray: string[], order: OrderType) => {
  return {
    order,
    validationFn: includes(__, fieldArray),
  }
}
