import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

export const useHrOptionStyles = makeStyles(() => ({
  label: {
    color: colors.black,
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '24px',
  },
  email: {
    color: colors.gray350,
  },
  listItem: {
    cursor: 'pointer',
    paddingLeft: 8,
    '&[aria-selected="true"]': {
      backgroundColor: colors.gray50,
    },
    '&:hover': {
      backgroundColor: colors.gray50,
    },
    '& .MuiCheckbox-root': {
      marginRight: 4,
    },
  },
}))
