import isEmpty from 'lodash/isEmpty'

import {CompanyFormValues} from '../CompanyForm/types'

const checkHrsFilled = (values: CompanyFormValues) => {
  return values.services.some(service => {
    if (service?.serviceFromGroup) {
      return service?.serviceFromGroup?.some(
        serviceInGroup => serviceInGroup?.isActive && serviceInGroup?.isHrsRequired && isEmpty(serviceInGroup?.hrs),
      )
    }
    return service.isActive && isEmpty(service?.hrs) && service.isHrsRequired
  })
}

export default checkHrsFilled
