import {useMemo} from 'react'

import assign from 'lodash/assign'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'

import {MergedCompanyInfo, MergedService} from 'pages/Company/types'
import {useGetAdminCompanyDetailsQuery, useGetAdminServicesQuery} from 'store/requests'
import {Id} from 'types'

export const useMergedCompanyServices = (
  companyConfigId?: Id,
): [MergedService[] | null, MergedCompanyInfo, boolean, any] => {
  const {
    data: companyConfig,
    error: companyConfigError,
    isLoading: isCompanyInfoLoading,
  } = useGetAdminCompanyDetailsQuery({id: Number(companyConfigId)}, {skip: !companyConfigId})
  const {
    data: servicesConfig,
    error: servicesConfigError,
    isLoading: isServicesConfigLoading,
  } = useGetAdminServicesQuery()

  const isLoading = isCompanyInfoLoading || isServicesConfigLoading
  const error = companyConfigError || servicesConfigError

  const mergedServices = useMemo(() => {
    if (!servicesConfig || (!companyConfig && companyConfigId)) return null
    const companyServicesObj = keyBy(companyConfig?.services || {}, 'id')
    return servicesConfig.map(
      ({id, isHrsRequired, hrServiceType, name, englishName, logo, isLimitedByAD, adGroups, hrGroup, ...rest}) => {
        const companyService = get(companyServicesObj, id, {id, isActive: false})

        const keysToAdd = omitBy(rest, it => !it)
        const paramsToAdd = {
          isHrsRequired,
          hrServiceType,
          name,
          englishName,
          logo,
          isLimitedByAD,
          adGroups,
          hrGroup,
        }
        Object.keys(keysToAdd).forEach(key => {
          if (!get(companyService, key)) {
            assign(paramsToAdd, {[key]: false})
          }
        })
        return {
          ...companyService,
          ...paramsToAdd,
          name,
          englishName,
          hrServiceType,
          logo,
          isLimitedByAD,
          adGroups,
          hrGroup,
        }
      },
    )
  }, [servicesConfig, companyConfig])

  const mergedCompanyInfo = {companyInfo: null, isArchived: false, ...(companyConfig || {}), services: mergedServices}
  return [mergedServices, mergedCompanyInfo, isLoading, error]
}
