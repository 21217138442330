export enum Errors {
  UNKNOWN_ERROR = 0,
  ENTRY_NOT_FOUND = 1,
  VALIDATION_ERROR = 2,
  UNAUTHORIZED = 3,
  EXISTING_SERVICE_NAME = 5,
  BAD_REQUEST = 6,
  COMPANY_CONFIG_EXISTS = 'CompanyConfigExists',
  EXIST_SERVICE = 23,
  EMPTY_SERVICE = 'AdmHrOverlap',
}

export const cancelMessage = 'Operations cancelled due to new request'
