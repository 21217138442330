import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import isEqual from 'lodash/isEqual'
import qs from 'qs'

import convertQsParamsToBooleans from '../utils/convertQsParamsToBooleans'

type QueryPrimitiveValue = string | number | undefined | null
type QueryArrayValue = QueryPrimitiveValue[]
type QueryValue = QueryPrimitiveValue | QueryArrayValue

export interface SetQueryParamsOptions {
  merge?: boolean
}

const useQueryParams = <T,>(sortingFunc?: any) => {
  const history = useHistory()
  const {search, pathname} = useLocation()

  const queryParams = React.useMemo(() => {
    const qsParams = qs.parse(search, {ignoreQueryPrefix: true, strictNullHandling: true})
    return convertQsParamsToBooleans(qsParams)
  }, [search]) as T

  const setQueryParams = React.useCallback(
    (nextParams: Record<string, QueryValue>, options?: SetQueryParamsOptions) => {
      const nextQueryParams = options?.merge ? {...queryParams, ...nextParams} : nextParams
      if (isEqual(nextQueryParams, queryParams)) {
        return
      }
      const stringifiedParams = qs.stringify(nextQueryParams, {
        skipNulls: true,
        sort: sortingFunc || false,
        arrayFormat: 'comma',
      })
      history.replace({pathname, search: decodeURIComponent(stringifiedParams)})
    },
    [history, pathname, queryParams],
  )

  return [queryParams, setQueryParams] as const
}

export default useQueryParams
