import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

const useOrderControlsStyles = makeStyles(() => ({
  orderContainer: {
    width: '35px',
    height: '100%',
    background: colors.gray60,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
  },
  orderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
  },
  orderInput: {
    margin: '0 5px',
    maxWidth: '28px',
    '& input': {
      padding: '4px 2px',
      backgroundColor: colors.white,
      fontSize: '14px',
      textAlign: 'center',
      borderRadius: '4px',
    },
    '& input:disabled': {
      color: colors.black,
      textFillColor: colors.black,
    },
  },
  orderIconTop: {
    transform: 'rotate(90deg)',
  },
  orderIconBottom: {
    transform: 'rotate(-90deg)',
  },
}))

export default useOrderControlsStyles
