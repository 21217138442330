import React from 'react'
import {useTranslation} from 'react-i18next'

import {Box} from '@mui/material'
import List from '@mui/material/List'
import {Typography} from 'ui-lib'

import adaptBase64ToFileObj from 'components/UploadingFile/utils'
import {ServiceGroupItem} from 'pages/Company/CompanyForm/types'

import CollapsedMenuItem from './CollapsedMenuItem'
import MenuItem from './MenuItem'
import useSideBarMenuStyles from './styles'

interface SideBarMenuProps {
  data: ServiceGroupItem[]
}

const SideBarMenu: React.FC<SideBarMenuProps> = ({data}) => {
  const classes = useSideBarMenuStyles()
  const {t} = useTranslation()

  return (
    <Box className={classes.container}>
      <Box className={classes.prompt}>
        <Typography variant="subtitle1"> Ось так виглядатиме меню підприємства</Typography>
      </Box>
      <List className={classes.list} component="ul" aria-labelledby="nested-list-subheader">
        {data.length ? (
          data?.map(service => {
            const fileObject = service.logo && adaptBase64ToFileObj([service.logo])
            const iconUrl = fileObject && URL.createObjectURL(fileObject[0].file)

            if (service.isGroup) {
              return (
                <CollapsedMenuItem key={service.id + service.name} name={service.name}>
                  {service.serviceFromGroup.map(serviceInGroup => {
                    const fileObject = serviceInGroup.logo && adaptBase64ToFileObj([serviceInGroup.logo])
                    const iconUrl = fileObject && URL.createObjectURL(fileObject[0].file)

                    return (
                      <MenuItem
                        key={serviceInGroup.id + serviceInGroup.name}
                        className={classes.groupItem}
                        name={serviceInGroup.name}
                        icon={iconUrl}
                      />
                    )
                  })}
                </CollapsedMenuItem>
              )
            }
            return <MenuItem key={service.id + service.name} name={service.name} icon={iconUrl} />
          })
        ) : (
          <Box className={classes.placeholderBox}>
            <Typography variant="subtitle2">{t('create-company.placeholder-menu')}</Typography>
          </Box>
        )}
      </List>
    </Box>
  )
}

export default SideBarMenu
