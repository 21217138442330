import get from 'lodash/get'

const getQueryParamsSortingFunction = (defaultRouteParams: object) => {
  const sortingFunc = (paramA: string, paramB: string) => {
    const paramAConfig = get(defaultRouteParams, paramA)
    const paramBConfig = get(defaultRouteParams, paramB)
    const orderA = get(paramAConfig, 'order', 0)
    const orderB = get(paramBConfig, 'order', 0)
    return orderA - orderB
  }

  return sortingFunc
}

export default getQueryParamsSortingFunction
