import React from 'react'
import {useTranslation} from 'react-i18next'

import {Box, Tooltip} from '@mui/material'
import isEmpty from 'lodash/isEmpty'

import {DefaultFile} from 'components/UploadingFile/UploadingFile'
import ServiceIcon from 'pages/Company/components/ServiceIcon/ServiceIcon'

import {useServicesAccessStyles} from './servicesAccessStyles'

const HolidayAccessItem = ({
  hrNames = [],
  isActive,
  logo,
}: {
  hrNames: string[]
  isActive: boolean
  logo: DefaultFile
}) => {
  const {t} = useTranslation()
  const classes = useServicesAccessStyles({isActive})

  const hrsMax = 15
  const isHrNamesMoreThenMax = hrNames.length > hrsMax
  const slicedHrNames = isHrNamesMoreThenMax ? hrNames.slice(0, hrsMax) : hrNames

  return (
    <Tooltip
      title={
        !isEmpty(hrNames) && (
          <Box className={classes.centeredText} data-test="holidays-service-hr-managers">
            <Box>{`${t('administration.hr-managers')}:`}</Box>
            {slicedHrNames.map(hrName => (
              <Box key={hrName}>{hrName}</Box>
            ))}
            {isHrNamesMoreThenMax && <Box>...</Box>}
          </Box>
        )
      }
    >
      <Box className={classes.iconWrapper}>
        <ServiceIcon isActive={isActive} logo={logo} />
      </Box>
    </Tooltip>
  )
}

export default HolidayAccessItem
