import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation, useParams} from 'react-router-dom'

import {Box} from '@mui/material'
import get from 'lodash/get'
import {ContentSpinner} from 'ui-lib'

import {adaptServiceData} from '../adapters/createServiceAdapter'
import {requestFormDataAdapter} from '../adapters/requestFormDataAdapter'
import ServiceForm from '../ServiceForm'
import {ResourceTypeEnum, ServiceFormValues} from '../ServiceForm/types'

import adaptBase64ToFileObj from 'components/UploadingFile/utils'
import {ServicesEnum} from 'constants/services'
import NotFound from 'pages/NotFound'
import routes from 'routes'
import {useGetServiceQuery} from 'store/services'
import {isStatusNotFound} from 'utils/handleError'

import useEditService from './hooks/useEditService'

const EditService = () => {
  const {t} = useTranslation()
  const {id} = useParams<{id: string}>()
  const {data, isLoading, error} = useGetServiceQuery({id})
  const [editService, {isLoading: isSubmitting}] = useEditService()
  const history = useHistory()
  const {state: locationState} = useLocation()

  const handleSubmit = async (values: ServiceFormValues) => {
    const adaptedData = adaptServiceData({...values, id})
    const adaptedToFormData = requestFormDataAdapter(adaptedData)

    await editService(adaptedToFormData)
  }

  const goBack = () => {
    const from = get(locationState, 'from')
    if (from) {
      history.goBack()
    } else {
      history.replace(routes.adminServicesList)
    }
  }

  if (isStatusNotFound(error)) {
    return <NotFound />
  }

  if (isLoading || !data) {
    return <ContentSpinner isLoading />
  }

  const initialValues = {
    ...data,
    resourceType: data.hrServiceType === ServicesEnum.EXTERNAL ? ResourceTypeEnum.EXTERNAL : ResourceTypeEnum.INTERNAL,
    logo: adaptBase64ToFileObj([data.logo]),
  }

  return (
    <Box sx={{position: 'relative'}}>
      <ContentSpinner isLoading={isSubmitting} alphaBackground={0.7} viewPortCentered>
        <ServiceForm
          onSubmit={handleSubmit}
          title={t('edit-service.title')}
          goBack={goBack}
          initialValues={initialValues}
        />
      </ContentSpinner>
    </Box>
  )
}

export default EditService
