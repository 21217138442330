import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import {Box, Button} from '@mui/material'

import {useAddButtonStyles} from './styles'

interface AddButtonProps {
  onAdd: () => void
  name: string
}

const AddButton: React.FC<AddButtonProps> = ({onAdd, name}) => {
  const classes = useAddButtonStyles()

  return (
    <Button fullWidth onClick={onAdd} className={classes.button} data-test="add-select-btn">
      <AddIcon />
      <Box>{name}</Box>
    </Button>
  )
}

export default AddButton
