import React from 'react'
import {useTranslation} from 'react-i18next'

import {Box, DialogActions, Divider} from '@mui/material'
import {Button, commonDimensions, ContentSpinner, Dialog, Typography} from 'ui-lib'

import useAddOrderDialogStyles from './styles'

interface DeleteConfirmationDialogProps {
  isOpen: boolean
  isDeleting: boolean
  onClose: () => void
  onSubmit: () => void
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({isOpen, onClose, onSubmit, isDeleting}) => {
  const {t} = useTranslation()
  const classes = useAddOrderDialogStyles()

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      data-test="date-overlapping-dialog"
      title={t('delete-confirmation.deleting-of-draft')}
      paperWidth={commonDimensions.dialogPaperWidth}
    >
      <ContentSpinner isLoading={isDeleting} alphaBackground={0.7}>
        <Box mx={3} mb={3}>
          <Typography className={classes.subTitle} data-test="dialog-sub-title" variant="h2">
            {t('delete-confirmation.you-are-going-to-delete')}
          </Typography>

          <Typography className={classes.description} data-test="dialog-title">
            {t('delete-confirmation.request-will-be-deleted')}
          </Typography>

          <Typography className={classes.description} data-test="dialog-title">
            {t('common-labels.confirm-your-action')}
          </Typography>
        </Box>
        <Divider />
        <DialogActions className={classes.dialogButtonsWrapper}>
          <Button onClick={onClose} type="default-secondary-yellow" data-test="dialog-active-action-btn">
            {t('actions.cancel')}
          </Button>
          <Button onClick={onSubmit} type="default-primary-yellow" data-test="dialog-active-action-btn">
            {t('actions.delete')}
          </Button>
        </DialogActions>
      </ContentSpinner>
    </Dialog>
  )
}

export default DeleteConfirmationDialog
