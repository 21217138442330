import {ServiceFormValues} from '../ServiceForm/types'

import {FileObject} from 'components/UploadingFile/UploadingFile'
import {AdGroupItem} from 'store/services'

interface ServiceDto extends Omit<ServiceFormValues, 'logo' | 'adGroups'> {
  adGroups?: AdGroupItem[] | string[] | null
  logo?: FileObject[] | null
}

export const requestFormDataAdapter = (values: ServiceDto): FormData => {
  const formData = new FormData()

  const handleArray = (key: string, array: FileObject[] | string[] | number[]) => {
    if (Array.isArray(array) && array.length) {
      array.forEach(item => {
        if (item instanceof Object && 'file' in item && 'id' in item) {
          formData.append('logo', (item as FileObject).file)
        } else {
          formData.append(`${key}`, String(item))
        }
      })
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in values) {
    // eslint-disable-next-line no-prototype-builtins
    if (values.hasOwnProperty(key)) {
      const value = values[key as keyof ServiceDto]
      if (value instanceof Date) {
        formData.append(key, value.toISOString())
      } else if (Array.isArray(value)) {
        handleArray(key, value as string[])
      } else {
        formData.append(key, String(value))
      }
    }
  }

  return formData
}
