import React, {useEffect} from 'react'
import {Prompt} from 'react-router-dom'

type PromptParams = {
  when: boolean
  message: string
}
export const useBeforeUnload = ({when, message}: PromptParams) => {
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (when) {
        event.preventDefault()
        // Seems not really used anymore
        event.returnValue = message
        return message
      }
      return undefined
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [when, message])
}

export const usePrompt = () => {
  const PromptRender = (props: PromptParams) => {
    useBeforeUnload(props)
    return <Prompt {...props} />
  }
  return {PromptRender}
}
