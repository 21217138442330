import React, {useState} from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {generatePath, Link, useHistory} from 'react-router-dom'

import Add from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {IconButton} from '@mui/material'
import clsx from 'clsx'
import {Button, PageHeader} from 'ui-lib'

import NoSearchDataWithButton from 'components/NoSearchDataWithButton/NoSearchDataWithButton'
import QueryControlledTable from 'components/QueryControlledTable'
import TableWrapper from 'components/TableWrapper'
import {DefaultFile} from 'components/UploadingFile/UploadingFile'
import useToggle from 'hooks/useToggle'
import useValidatedQueryParams from 'hooks/useValidatedQueryParams'
import DeleteConfirmationDialog from 'pages/Company/CompaniesList/components/DeleteConfirmationDialog'
import {queryParamsConfig} from 'pages/Service/ServicesList/queryParamsConfig'
import routes from 'routes'
import {ServiceItem, useGetServicesListQuery} from 'store/services'
import {PaginationQueryParams, SortQueryParams} from 'types'
import {paginationAdapter} from 'utils/adapters/paginationAdapter'

import useDeleteService from './hooks/useDeleteService'
import {defaultFileToUrl, getServiceTypeLabel} from './utils'
import {useTableStyles} from './styles'

interface EmployeeRequestsListPageQueryParams extends PaginationQueryParams, SortQueryParams {}

const ServicesList: React.FC = () => {
  // ------ hooks ------
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useTableStyles()
  const [isDeleteModalVisible, openDeleteModal, closeDeleteModal] = useToggle()
  const [serviceIdToDelete, setServiceIdToDelete] = useState<number | null>(null)

  // ------ queries ------
  const [queryParams] = useValidatedQueryParams<EmployeeRequestsListPageQueryParams>({queryParamsConfig})
  const {data, isFetching, isSuccess, isError} = useGetServicesListQuery(paginationAdapter(queryParams))
  const [deleteService, {isDeleting}] = useDeleteService()

  const handleOpenModal = (id: number) => {
    openDeleteModal()
    setServiceIdToDelete(id)
  }

  const handleDeleteService = async () => {
    if (serviceIdToDelete) {
      await deleteService(serviceIdToDelete)
      closeDeleteModal()
      setServiceIdToDelete(null)
    }
  }

  return (
    <>
      <Helmet title={t('service-list.title')} />
      <PageHeader headerTitleClass={classes.headerClass} title={t('service-list.title')}>
        <Button
          startIcon={<Add />}
          type="cart-secondary"
          component={Link}
          to={routes.createService}
          data-test="create-service-btn"
        >
          {t('service-list.create-service')}
        </Button>
      </PageHeader>

      <TableWrapper>
        <QueryControlledTable<ServiceItem>
          isDataLoaded={isError || isSuccess}
          className={classes.table}
          columns={[
            {
              width: '10%',
              id: 'id',
              label: t('service-list.column-id'),
              className: classes.idCell,
              'data-test': 'requests-list-id',
            },
            {
              width: '20%',
              id: 'name',
              label: t('service-list.service-name'),
              'data-test': 'name',
            },
            {
              width: '20%',
              id: 'englishName',
              label: t('service-list.en-service-name'),
              'data-test': 'english-name',
            },
            {
              width: '10%',
              id: 'logo',
              label: t('service-list.icon'),
              'data-test': 'logo',
              render: (logo: DefaultFile) => (
                <img className={classes.logo} src={defaultFileToUrl(logo)} alt={logo.fileDownloadName} />
              ),
            },
            {
              width: '20%',
              id: 'hrServiceType',
              label: t('service-list.type'),
              'data-test': 'hr-service-type',
              render: getServiceTypeLabel,
            },
            {
              width: '15%',
              id: 'updatedAt',
              'data-test': 'updated-at',
              label: t('service-list.updated-at'),
            },
            {
              width: '5%',
              id: 'delete',
              label: '',
              'data-test': 'delete',
              render: (_, row) => (
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    handleOpenModal(row.id)
                  }}
                  size="small"
                  className={clsx(classes.deleteIcon, 'deleteIcon')}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              ),
            },
          ]}
          data={data?.items ?? []}
          rowCount={data?.totalCount ?? 0}
          loading={isFetching}
          onRow={row => ({
            className: classes.tableRow,
            dataTest: `request-list-row-${row.id}`,
            onClick: () => {
              const state = {from: queryParams}
              history.push(generatePath(routes.editService, {id: row.id}), state)
            },
          })}
          pagination={{
            itemsCount: data?.totalCount as number,
          }}
          noDataContent={
            <NoSearchDataWithButton
              buttonProps={{
                onClick: () => history.push(routes.createService),
                dataTest: 'create-service-btn',
                htmlType: 'button',
                type: 'cart-secondary',
                startIcon: <Add />,
                label: t('service-list.create-service'),
              }}
            />
          }
        />
      </TableWrapper>

      <DeleteConfirmationDialog
        isOpen={isDeleteModalVisible}
        isDeleting={isDeleting}
        onClose={closeDeleteModal}
        onSubmit={handleDeleteService}
      />
    </>
  )
}

export default ServicesList
