import {makeStyles} from '@mui/styles'
import {colors, commonDimensions} from 'ui-lib'

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
  },
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: `calc(100vh - 220px)`,
  },
  actionControl: {
    marginLeft: 24,
  },
  caseBtn: {
    marginRight: 16,
    marginBottom: 16,
  },
  caseBtnSubTitle: {
    marginBottom: 16,
  },
  flexWrapBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  flexWraIndented: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 15%',
    boxSizing: 'border-box',
  },
  redColor: {
    color: colors.error,
  },
  filterElement: {
    marginTop: 32,
    marginBottom: 32,
  },
  tabs: {
    '& .MuiTab-root': {
      borderBottom: `2px solid ${colors.gray300}`,
      textTransform: 'none',
      fontSize: 16,
      color: colors.black,
    },
    '& .Mui-selected': {
      fontWeight: 'bold',
      backgroundColor: colors.yellow100,
    },
  },
  stickyTabs: {
    position: 'sticky',
    top: 128,
    backgroundColor: colors.white,
    zIndex: 10,
  },
  indicator: {
    height: 4,
    backgroundColor: colors.yellow500,
  },
  mainContainer: {
    padding: `${commonDimensions.headerHeight} 0 0`,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1024px',
    backgroundColor: colors.gray100,
  },
  mainContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: 24,
    background: colors.white,
    borderRadius: 3,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    border: `1px solid ${colors.gray200}`,
    minHeight: `calc(100vh -
      ${commonDimensions.headerHeight} -
      ${commonDimensions.mainBottomMargin} -
      ${commonDimensions.borderWidth} -
      ${commonDimensions.mainTopMargin}
    )`,
  },
}))

export default useStyles
