import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import {Box, Button} from '@mui/material'
import clsx from 'clsx'

import useToggle from 'hooks/useToggle'
import {IDialogOption} from 'pages/Company/types'

import DialogWindow from './DialogWindow'
import {useDialogSelectStyles} from './styles'

export interface SelectInfo {
  title: string
  description: string
  headerTitle: string
  request?: (query: string) => Promise<IDialogOption[]>
  inputName: string
  inputPlaceholder: string
  selectPlaceholder?: string
}

interface SelectProps {
  selectInfo: SelectInfo
  className?: string
  values?: IDialogOption[]
  disabled?: boolean
  disabledHover?: boolean
  onChange: (value?: IDialogOption[]) => void
  onDelete: (id: number) => void
  SelectedOptionComponent: React.ElementType
  isEditPage?: boolean
  containerClassName?: string
  selectedDisabledIds?: number[]
  multiple?: boolean
}

const DialogEmployeeSelect: React.FC<SelectProps> = ({
  values,
  onChange,
  onDelete,
  className,
  disabled,
  disabledHover,
  selectInfo,
  SelectedOptionComponent,
  containerClassName,
  selectedDisabledIds,
  multiple = false,
}) => {
  const [isDialogVisible, showDialog, closeDialog] = useToggle()
  const classes = useDialogSelectStyles()
  const history = useHistory()
  const params = new URLSearchParams(window.location.search)
  const openByDefault = params.get('open-by-default')

  const handleSubmit = (value?: IDialogOption[]) => {
    if (value) {
      onChange([...value])
    }
    closeDialog()
  }

  useEffect(() => {
    if (openByDefault) {
      showDialog()
      history.replace({search: ''})
    }
  }, [])

  return (
    <Box className={clsx(classes.container, containerClassName)}>
      <Box className={clsx(values?.length && classes.wrapperWithLength, className)}>
        {(multiple || !values?.length) && (
          <Button
            data-test="add-new-employee-btn"
            fullWidth
            onClick={showDialog}
            disabled={disabled}
            className={clsx(values?.length ? classes.buttonWidthValues : classes.button, disabled && classes.disabled)}
          >
            <AddIcon />
            <Box>{selectInfo.selectPlaceholder}</Box>
          </Button>
        )}

        {values?.map(value => (
          <SelectedOptionComponent
            key={value?.id}
            onClick={disabledHover ? undefined : () => showDialog()}
            onIconClick={(id: number) => onDelete(id)}
            disabledHover={disabledHover}
            {...value}
          />
        ))}
      </Box>

      <DialogWindow
        selectInfo={selectInfo}
        open={isDialogVisible}
        onClose={closeDialog}
        onSubmit={handleSubmit}
        values={values}
        selectedDisabledIds={selectedDisabledIds}
      />
    </Box>
  )
}

export default DialogEmployeeSelect
