import {
  getDateValidationConfig,
  getHrsValidationConfig,
  getCompaniesValidationConfig,
  getLimitOffsetValidationConfig,
  getSortingFieldValidationConfig,
  getSortingOrderValidationConfig,
  getEntityTypeValidationConfig,
} from 'utils/validation'

import {FilterOptions} from './types'

export const getQueryParamsConfig = (options: FilterOptions) => ({
  companyIds: {func: getCompaniesValidationConfig(options, 1)},
  hrIds: {func: getHrsValidationConfig(options, 3)},
  updatedAt: {func: getDateValidationConfig(9)},
  entityType: {func: getEntityTypeValidationConfig(['any', 'archived', 'active'], 10)},
  limit: {func: getLimitOffsetValidationConfig(11)},
  page: {func: getLimitOffsetValidationConfig(12)},
  sortingField: {func: getSortingFieldValidationConfig(['id', 'companyName', 'updatedAt'], 13)},
  sortingOrder: {func: getSortingOrderValidationConfig(14)},
})
