import {initReactI18next} from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

import translationEN from 'locales/en/translation.json'
import translationUA from 'locales/ua/translation.json'

export const availableLanguagesOptions = [
  {value: 'en', label: 'English'},
  {value: 'ua', label: 'Українська'},
]

const resources = {
  en: {
    translation: translationEN,
  },
  ua: {
    translation: translationUA,
  },
}

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    resources,
    lng: 'ua',
    fallbackLng: 'ua',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
