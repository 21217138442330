import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {CancelOutlined, CheckCircleOutline} from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {Box, Dialog, IconButton, InputAdornment, TextField} from '@mui/material'
import clsx from 'clsx'
import {debounce} from 'lodash'
import {isEmpty} from 'lodash/fp'
import get from 'lodash/get'
import {Button, ContentSpinner, NoSearchData as NoDataPlaceholder, Typography} from 'ui-lib'

import {ReactComponent as NoResultsImage} from 'assets/noResultsEmployee.svg'
import {useMergedCompanyServices} from 'hooks/useMergedCompanyServices'
import {MergedService, ServiceItem} from 'pages/Company/types'
import NotFound from 'pages/NotFound'

import DialogOption from './DialogOption'
import {SelectInfo} from './DialogSelectService'
import {useDialogWindowStyles} from './styles'

interface DialogWindowProps {
  selectInfo: SelectInfo
  open: boolean
  onClose: () => void
  onSubmit: (value?: ServiceItem[]) => void
  values?: ServiceItem[]
  selectedDisabledIds?: number[]
}

const DialogWindow: React.FC<DialogWindowProps> = ({
  open,
  onClose,
  onSubmit,
  values,
  selectInfo,
  selectedDisabledIds,
}) => {
  const {t} = useTranslation()
  const classes = useDialogWindowStyles()
  const {id} = useParams<{id: string}>()
  const [inputValue, setInputValue] = useState('')
  const [localValue, setLocalValue] = useState(values)
  const [mergedServices, , isLoadingServices, error] = useMergedCompanyServices(id)
  const [options, setOptions] = useState<MergedService[] | null>(mergedServices)

  const errorStatus = get(error, 'status')

  const minSearchStringLength = 3
  const containerRef = useRef<HTMLDivElement>(null)

  const maxResultItemsCount = 12
  const showsNoSearchData = inputValue.length >= minSearchStringLength && isEmpty(options)
  const showSearchResult = !isEmpty(options)
  const showEmployeesLimitsReached = options && options?.length >= maxResultItemsCount
  const showSearchPrompt = isEmpty(options) && inputValue.length < minSearchStringLength

  const onClearInput = () => {
    setInputValue('')
    setOptions(mergedServices)
  }

  const handleSubmit = () => {
    const newValue = localValue?.map(value => ({...value})) as ServiceItem[]
    onSubmit(newValue)
    onClearInput()
    setLocalValue(undefined)
    onClose()
  }

  const handleClose = () => {
    setLocalValue(values)
    onClearInput()
    onClose()
  }

  const debouncedSearch = React.useMemo(
    () =>
      debounce((value: string) => {
        const filtered =
          mergedServices?.filter(
            service =>
              service.name.toLowerCase().includes(value.toLowerCase()) ||
              service.englishName.toLowerCase().includes(value.toLowerCase()),
          ) || null

        setOptions(filtered)
      }, 300),
    [],
  )

  const handleSearchByInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target
    setInputValue(value)

    if (value.length >= minSearchStringLength) {
      debouncedSearch(value)
    } else {
      setOptions(mergedServices)
    }
  }

  const onOptionClick = (option: MergedService) => {
    setLocalValue([option as ServiceItem])
  }

  useEffect(() => {
    setLocalValue(values)
  }, [values])

  useEffect(() => {
    setOptions(mergedServices)
  }, [mergedServices])

  if (errorStatus === 404) {
    return <NotFound />
  }

  if (isLoadingServices) return <ContentSpinner isLoading />

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <Box className={clsx()} ref={containerRef}>
        <Box className={classes.header}>
          <Typography variant="h1">{selectInfo.headerTitle}</Typography>
          <Button
            htmlType="button"
            startIcon={<CancelOutlined />}
            type="cart-secondary"
            onClick={handleClose}
            data-test="cancel-btn"
          >
            {t('create-company.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            htmlType="button"
            startIcon={<CheckCircleOutline />}
            type="cart-secondary"
            data-test="create-btn"
            disabled={!localValue?.length}
          >
            {t('create-company.submit')}
          </Button>
        </Box>

        <Box className={classes.container}>
          <Typography textAlign="center" variant="h2">
            {selectInfo.title}
          </Typography>
          <Typography textAlign="center" variant="subtitle2" className={classes.subtitle}>
            {selectInfo.description}
          </Typography>

          <TextField
            className={classes.input}
            name={selectInfo.inputName}
            label={selectInfo.inputPlaceholder}
            data-test="service-text-field"
            onChange={handleSearchByInputValue}
            value={inputValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton data-test="clear-input" onClick={onClearInput} edge="end">
                    {inputValue.length ? <HighlightOffIcon /> : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{position: 'relative', minHeight: '50vh'}}>
          {showSearchResult && (
            <Box className={classes.itemContainer}>
              {options?.map(option => {
                const isSelected = localValue?.some(value => (value.id || value.id) === option.id)
                const isSelectedDisabled = selectedDisabledIds?.includes(Number(option.id))

                return (
                  <DialogOption
                    key={option.id}
                    selected={isSelected}
                    selectedDisabled={isSelectedDisabled}
                    onClick={() => onOptionClick(option)}
                    value={{
                      name: option.name,
                      englishName: option.englishName,
                    }}
                  />
                )
              })}
            </Box>
          )}

          {showsNoSearchData && (
            <Box className={classes.noSearchBlock}>
              <NoDataPlaceholder
                title={t('create-company.no-data-to-display')}
                subtitles={[
                  t('create-company.no-information-to-display-for-your-request'),
                  t('create-company.try-changing-your-request'),
                ]}
              />
            </Box>
          )}

          {showSearchPrompt && (
            <NoDataPlaceholder
              ImageComponent={NoResultsImage}
              title={t('create-company.no-data-to-display')}
              subtitles={[t('create-company.select-at-least-three-characters')]}
            />
          )}

          {showEmployeesLimitsReached && (
            <Box className={classes.contentHeader} data-test="limits-have-been-reached">
              <Typography variant="subtitle2" fontWeight="bold">
                {t('dialog-window.limits-have-been-reached')}
              </Typography>
              <Typography mt={1} variant="body2" fontSize={16}>
                {t('dialog-window.refine-your-request-to-reduce')}
              </Typography>
              <Typography variant="body2" fontSize={16}>
                {t('dialog-window.results-count')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}

export default DialogWindow
