import React from 'react'
import {useTranslation} from 'react-i18next'

import {Add} from '@mui/icons-material'
import {Box} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import {Form, Formik, FormikProps} from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import {Button} from 'ui-lib'
import * as Yup from 'yup'

import Autocomplete from 'components/FormAutocomplete'
import HrAutocompleteOption from 'pages/Company/CompaniesList/components/HrAutocompleteOption'
import useStyles from 'pages/Company/CompaniesList/RequestFiltersFormStyles'
import {FilterOptions} from 'pages/Company/CompaniesList/types'
import {hrOptionsFilterFunc} from 'pages/Company/CompaniesList/utils'

const requestFiltersFormValidationSchema = Yup.object({
  hrIds: Yup.array()
    .of(
      Yup.object({
        value: Yup.number(),
        label: Yup.string(),
      }),
    )
    .notRequired(),
  companyIds: Yup.array()
    .of(
      Yup.object({
        value: Yup.number(),
        label: Yup.string(),
      }),
    )
    .notRequired(),
  entityType: Yup.string().notRequired(),
})

export type RequestFiltersFormValues = Yup.InferType<typeof requestFiltersFormValidationSchema>

export type RequestFiltersFormInitialState = {
  hrIds?: number[]
  companyIds?: number[]
  entityType?: string
}

export interface RequestFiltersFormProps {
  onReset: () => void
  onSubmit: (values: RequestFiltersFormValues) => void
  initialValues: RequestFiltersFormInitialState
  hasActiveFilters: boolean
  hasSelectedItems: boolean
  options: FilterOptions
}

export const emptyRequestFiltersFormValues: RequestFiltersFormValues = {
  hrIds: [],
  companyIds: [],
  entityType: 'any',
}

const RequestFiltersForm: React.FC<RequestFiltersFormProps> = ({
  initialValues,
  onSubmit,
  onReset,
  options,
  hasActiveFilters,
  hasSelectedItems,
}) => {
  const classes = useStyles({hasSelectedItems})
  // hooks
  const {t} = useTranslation()
  const formRef = React.useRef<FormikProps<RequestFiltersFormValues>>(null)
  const initialStateRef = React.useRef<RequestFiltersFormInitialState | null>(null)

  // effects
  React.useEffect(() => {
    function init() {
      if (isEqual(initialValues, initialStateRef.current)) {
        return
      }
      initialStateRef.current = initialValues

      const {hrIds = [], companyIds = [], entityType = 'any'} = initialValues
      const hrOptions = get(options, 'hrs', [])

      const companiesOptions = get(options, 'companies', [])
      const hrsFormValues = hrOptions.filter(({value}) => hrIds.includes(value))
      const companiesFormValues = companiesOptions.filter(({value}) => companyIds.includes(value))

      formRef.current?.resetForm({
        values: {
          hrIds: hrsFormValues,
          companyIds: companiesFormValues,
          entityType,
        },
      })
    }

    init()
  }, [initialValues])
  return (
    <Formik<RequestFiltersFormValues>
      innerRef={formRef}
      initialValues={emptyRequestFiltersFormValues}
      validationSchema={requestFiltersFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({dirty, resetForm, setFieldTouched, setFieldValue, values}) => {
        const handleHrsChange = async (e: unknown, options: any) => {
          setFieldValue('hrIds', options)
          setFieldTouched('hrIds', true)
        }

        const handleCompanyChange = async (e: unknown, options: any) => {
          setFieldValue('companyIds', options)
          setFieldTouched('companyIds', true)
        }

        const handleEntityTypeChange = async (e: unknown) => {
          const value = get(e, 'target.value')
          setFieldValue('entityType', value)
          setFieldTouched('entityType', true)
        }

        const handleReset = () => {
          if (hasActiveFilters) {
            onReset()
          } else {
            resetForm({
              values: {
                ...emptyRequestFiltersFormValues,
              },
            })
          }
        }

        return (
          <Form className={classes.form}>
            <Box className={classes.formHeader}>
              <h6 className={classes.formHeaderTitle}>{t('filters.title')}</h6>
              <Button
                onClick={handleReset}
                disabled={!dirty && !hasActiveFilters}
                type="cart-secondary"
                data-test="filters-clear-button"
              >
                {t('filters.clear')}
              </Button>
            </Box>
            <Box className={classes.formWrapper}>
              <Box className={classes.formContainer}>
                <Autocomplete
                  name="companyIds"
                  multiple
                  label={t('filters.company-name')}
                  options={options?.companies || []}
                  onChange={handleCompanyChange}
                  popupIcon={<Add />}
                />
                <Autocomplete
                  name="hrIds"
                  multiple
                  label={t('filters.hr-manager')}
                  options={options?.hrs || []}
                  onChange={handleHrsChange}
                  popupIcon={<Add />}
                  renderOption={(props, option, state) => (
                    <HrAutocompleteOption {...props} state={state} option={option} />
                  )}
                  filterOptions={hrOptionsFilterFunc}
                />
                <RadioGroup
                  value={values.entityType}
                  onChange={handleEntityTypeChange}
                  aria-labelledby="radio-buttons-group"
                  name="radio-buttons-group"
                  data-test="types-group"
                >
                  <FormControlLabel
                    className={classes.radioLabel}
                    value="any"
                    control={<Radio />}
                    label={t('administration.all-values')}
                    data-test="types-group-all"
                  />
                  <FormControlLabel
                    className={classes.radioLabel}
                    value="active"
                    control={<Radio />}
                    label={t('administration.active-values')}
                    data-test="types-group-active"
                  />
                  <FormControlLabel
                    className={classes.radioLabel}
                    value="archived"
                    control={<Radio />}
                    label={t('administration.archived-values')}
                    data-test="types-group-archived"
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box className={classes.formFooter}>
              <Button
                type="default-primary-yellow"
                htmlType="submit"
                fullWidth
                className={classes.submitButton}
                disabled={!dirty}
                data-test="filters-submit-button"
              >
                {t('filters.submit')}
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default React.memo(RequestFiltersForm)
