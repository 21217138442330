import React from 'react'

import {Box} from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import clsx from 'clsx'
import {Typography} from 'ui-lib'

import useSideBarMenuStyles from './styles'

interface MenuItemProps {
  name: string
  icon?: string
  onClick?: () => void
  className?: string
}

const MenuItem: React.FC<MenuItemProps> = ({name, icon, onClick = () => {}, className}) => {
  const classes = useSideBarMenuStyles()

  return (
    <ListItemButton data-test="menu-item" onClick={onClick} className={clsx(classes.menuItem, className)}>
      {icon && (
        <Box className={classes.iconBox}>
          <img src={icon} alt={name} />
        </Box>
      )}
      <Typography variant="subtitle2">{name}</Typography>
    </ListItemButton>
  )
}

export default MenuItem
