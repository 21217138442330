import {t} from 'i18next'

import {DefaultFile} from 'components/UploadingFile/UploadingFile'
import adaptBase64ToFileObj from 'components/UploadingFile/utils'
import {ServicesEnum} from 'constants/services'

export const getServiceTypeLabel = (hrServiceType: ServicesEnum) =>
  hrServiceType === ServicesEnum.EXTERNAL ? t('service-list.external') : t('service-list.internal')

export const defaultFileToUrl = (logo: DefaultFile) => {
  const fileObj = adaptBase64ToFileObj([logo])
  const iconUrl = fileObj && URL.createObjectURL(fileObj[0].file)
  return iconUrl
}
