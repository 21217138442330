import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useHistory} from 'react-router-dom'

import {CancelOutlined, CheckCircleOutline} from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {Box, Dialog, IconButton, InputAdornment, TextField} from '@mui/material'
import clsx from 'clsx'
import {isEmpty} from 'lodash/fp'
import {Button, ContentSpinner, NoSearchData as NoDataPlaceholder, Typography} from 'ui-lib'

import DialogOption from '../DialogOption'

import {ReactComponent as NoResultsImage} from 'assets/noResultsEmployee.svg'
import ExistingCompanyDialog from 'components/Dialogs/ExistingCompanyDialog'
import {IDialogOption} from 'pages/Company/types'
import routes from 'routes'

import {SelectInfo} from './DialogSelect'
import {useDialogWindowStyles} from './styles'

interface DialogWindowProps {
  selectInfo: SelectInfo
  open: boolean
  onClose: () => void
  onSubmit: (value?: IDialogOption[]) => void
  values?: IDialogOption[]
  selectedDisabledIds?: number[]
}

const DialogWindow: React.FC<DialogWindowProps> = ({
  open,
  onClose,
  onSubmit,
  values,
  selectInfo,
  selectedDisabledIds,
}) => {
  const {t} = useTranslation()
  const classes = useDialogWindowStyles()
  const history = useHistory()
  const [inputValue, setInputValue] = useState('')
  const [localValue, setLocalValue] = useState(values)
  const [options, setOptions] = useState<IDialogOption[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [disabledOption, setDisabledOption] = useState<number | undefined>(undefined)

  const minSearchStringLength = 3
  const containerRef = useRef<HTMLDivElement>(null)

  const maxResultItemsCount = 12
  const showsNoSearchData = inputValue.length >= minSearchStringLength && isEmpty(options) && !isLoading
  const showSearchResult = !isEmpty(options)
  const showEmployeesLimitsReached = options && options?.length >= maxResultItemsCount
  const showSearchPrompt = inputValue.length < minSearchStringLength

  const canselMessage = 'Operations cancelled due to new request'

  const onClearInput = () => {
    setInputValue('')
    setOptions(undefined)
  }

  const handleSubmit = () => {
    const newValue = localValue?.map(value => ({...value})) as IDialogOption[]
    onSubmit(newValue)
    onClearInput()
    setLocalValue(undefined)
    onClose()
  }

  const handleClose = () => {
    setLocalValue(values)
    onClearInput()
    onClose()
  }

  const handleSearchByInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)

    if (e.target.value.length >= minSearchStringLength) {
      setIsLoading(true)

      if (selectInfo.request) {
        selectInfo
          .request(e.target.value.trim())
          .then(res => {
            setOptions(res)
            setIsLoading(false)
          })
          .catch(e => {
            if (e.message !== canselMessage) {
              setIsLoading(false)
            }
          })
      }
    } else {
      setOptions(undefined)
    }
  }

  const onOptionClick = (option: IDialogOption) => {
    setLocalValue([option])
  }

  const handleGoToCompanyConfig = () => {
    history.push(generatePath(routes.editCompany, {id: disabledOption}))
  }

  useEffect(() => {
    setLocalValue(values)
  }, [values])

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <Box className={clsx()} ref={containerRef}>
        <Box className={classes.header}>
          <Typography variant="h1">{selectInfo.headerTitle}</Typography>
          <Button
            htmlType="button"
            startIcon={<CancelOutlined />}
            type="cart-secondary"
            onClick={handleClose}
            data-test="cancel-btn"
          >
            {t('create-company.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            htmlType="button"
            startIcon={<CheckCircleOutline />}
            type="cart-secondary"
            data-test="create-btn"
            disabled={!localValue?.length}
          >
            {t('create-company.submit')}
          </Button>
        </Box>

        <Box className={classes.container}>
          <Typography textAlign="center" variant="h2">
            {selectInfo.title}
          </Typography>
          <Typography textAlign="center" variant="subtitle2" className={classes.subtitle}>
            {selectInfo.description}
          </Typography>

          <TextField
            className={classes.input}
            name={selectInfo.inputName}
            label={selectInfo.inputPlaceholder}
            data-test="company-text-field"
            onChange={handleSearchByInputValue}
            value={inputValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton data-test="clear-input" onClick={onClearInput} edge="end">
                    {inputValue.length ? <HighlightOffIcon /> : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{position: 'relative', minHeight: '50vh'}}>
          <ContentSpinner alphaBackground={0.7} viewPortCentered={false} isLoading={isLoading}>
            {showSearchResult && (
              <Box className={classes.itemContainer}>
                {options?.map(option => {
                  const isSelected = localValue?.some(value => (value.employeeId || value.id) === option.id)
                  const isSelectedDisabled =
                    selectedDisabledIds?.includes(Number(option.id)) || !!option?.companyConfigId

                  return (
                    <DialogOption
                      key={option.id}
                      selected={isSelected}
                      selectedDisabled={isSelectedDisabled}
                      onClick={() => onOptionClick(option)}
                      value={{...option}}
                      onRedirect={
                        option?.companyConfigId ? () => setDisabledOption(option?.companyConfigId) : undefined
                      }
                    />
                  )
                })}
              </Box>
            )}

            {showsNoSearchData && (
              <Box className={classes.noSearchBlock}>
                <NoDataPlaceholder
                  title={t('create-company.no-data-to-display')}
                  subtitles={[
                    t('create-company.no-information-to-display-for-your-request'),
                    t('create-company.try-changing-your-request'),
                  ]}
                />
              </Box>
            )}

            {showSearchPrompt && (
              <NoDataPlaceholder
                ImageComponent={NoResultsImage}
                title={t('create-company.no-data-to-display')}
                subtitles={[t('create-company.select-at-least-three-characters')]}
              />
            )}

            {showEmployeesLimitsReached && (
              <Box className={classes.contentHeader} data-test="limits-have-been-reached">
                <Typography variant="subtitle2" fontWeight="bold">
                  {t('dialog-window.limits-have-been-reached')}
                </Typography>
                <Typography mt={1} variant="body2" fontSize={16}>
                  {t('dialog-window.refine-your-request-to-reduce')}
                </Typography>
                <Typography variant="body2" fontSize={16}>
                  {t('dialog-window.results-count')}
                </Typography>
              </Box>
            )}
          </ContentSpinner>
        </Box>
      </Box>

      <ExistingCompanyDialog
        onSubmit={handleGoToCompanyConfig}
        onClose={() => setDisabledOption(undefined)}
        isOpen={!!disabledOption}
      />
    </Dialog>
  )
}

export default DialogWindow
