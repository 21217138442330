import React from 'react'

import {Box} from '@mui/material'
import clsx from 'clsx'

import {useTableWrapperStyles} from './styles'

interface TableWrapperProps {
  className?: string
}

const TableWrapper: React.FC<TableWrapperProps> = ({children, className}) => {
  const classes = useTableWrapperStyles()

  return <Box className={clsx(classes.tableWrapper, className)}>{children}</Box>
}

export default TableWrapper
