import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import CancelOutlined from '@mui/icons-material/CancelOutlined'
import {Box} from '@mui/material'
import {Button, Tag, TextField} from 'ui-lib'

import {useSnackbar} from 'hooks/useSnackbar'
import {AdGroupItem, useLazyValidateAdGroupQuery} from 'store/services'

import useAdStyles from './styles'

interface AdGroupsInputProps {
  values?: AdGroupItem[]
  onChange: (values?: AdGroupItem[]) => void
  error: string
}

const ActiveDirectoryInput: React.FC<AdGroupsInputProps> = ({values = [], onChange, error}) => {
  const {t} = useTranslation()
  const classes = useAdStyles()
  const [localValue, setLocalValue] = useState('')
  const [validateAdGroup] = useLazyValidateAdGroupQuery()
  const enqueueSnackbar = useSnackbar()

  const handleSubmit = async () => {
    try {
      const trimmedValue = localValue.trim()
      const res = await validateAdGroup({query: trimmedValue}).unwrap()
      const hasSameValue = values.some(value => value.name === localValue)

      if (localValue && !hasSameValue && res.id) {
        const newArray: AdGroupItem[] = []
        newArray.push(...values, res)

        onChange(newArray)
        setLocalValue('')
      }

      if (!res.id) {
        throw Error('AD not found ')
      }
    } catch (error) {
      enqueueSnackbar(t('create-service.ad-error'), {variant: 'error'})
    }
  }

  const handleDelete = (groupItemId: string) => {
    const filteredValues = values?.filter(value => value.id !== groupItemId)
    onChange(filteredValues?.length ? filteredValues : undefined)
  }

  return (
    <Box>
      <Box className={classes.inputContainer}>
        <TextField
          error={!!error}
          value={localValue}
          label={t('create-service.active-directory')}
          inputProps={{maxLength: 256, 'data-test': 'active-directory-input'}}
          helperText={t('validation.required')}
          onChange={e => setLocalValue(e.target.value)}
        />

        <Button className={classes.button} type="default" onClick={handleSubmit}>
          {t('create-service.btn-add')}
        </Button>
      </Box>

      <Box className={classes.tagsContainer}>
        {values.map(groupItem => {
          return (
            <Tag
              className={classes.tag}
              key={groupItem.id}
              label={groupItem.name}
              deleteIcon={<CancelOutlined />}
              size="medium"
              onDelete={() => handleDelete(groupItem.id)}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default ActiveDirectoryInput
