import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import cond from 'lodash/fp/cond'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy'

import useQueryParams from 'hooks/useQueryParams'
import {RequestsListPageParams} from 'types'
import getQueryParamsSortingFunction from 'utils/getQueryParamsSortingFunction'

type ValidationOptions = {
  skip?: boolean
}
const useValidatedQueryParams = <T,>(
  {defaultQueryParams = {}, queryParamsConfig = {}} = {},
  {skip = false}: ValidationOptions = {},
) => {
  const {search} = useLocation()
  const sortingFunc = getQueryParamsSortingFunction(queryParamsConfig)
  const [queryParams, setQueryParams] = useQueryParams<RequestsListPageParams>(sortingFunc)
  const validatedQueryParams = React.useMemo(() => {
    if (skip) {
      return null
    }
    const queryParamsEntries = Object.entries(queryParams)

    const prepareQueryParams = queryParamsEntries.reduce(
      (acc: any, [key, value]: any) =>
        cond([
          [
            ({key, value}: any) => {
              const paramConfig = get(queryParamsConfig, key)
              const validationFn = get(paramConfig?.func, 'validationFn')
              if (typeof validationFn === 'function') {
                return validationFn(value, queryParams, paramConfig?.dependencies)
              }
              return false
            },
            () => {
              const paramConfig = get(queryParamsConfig, key)
              const updateFunction = get(paramConfig?.func, 'updateFunction')
              if (typeof updateFunction === 'function') {
                return {
                  ...acc,
                  [key]: updateFunction(value),
                }
              }
              return {
                ...acc,
                [key]: value,
              }
            },
          ],
          [() => true, () => acc],
        ])({key, value}),
      {},
    )

    const preparedDefaultQueryParams = Object.entries(defaultQueryParams).reduce(
      (acc: any, [key, value]: any) =>
        cond([
          [
            ({key}: any) => {
              const paramConfig = get(queryParamsConfig, key)
              const validationDependencies = paramConfig?.dependencies || {}
              return Object.entries(validationDependencies).every(([dependencyKey, dependencyValue]) => {
                return get(prepareQueryParams, dependencyKey) === dependencyValue
              })
            },
            () => {
              return {
                ...acc,
                [key]: value,
              }
            },
          ],
          [() => true, () => acc],
        ])({key, value}),
      {},
    )

    const mergeQueryParams = {
      ...preparedDefaultQueryParams,
      ...prepareQueryParams,
    }
    return omitBy(mergeQueryParams, (value, key) => {
      const paramConfig = get(queryParamsConfig, key)
      const excludeFn = get(paramConfig, 'excludeFn')
      if (typeof excludeFn === 'function') return excludeFn(mergeQueryParams)
      return false
    })
  }, [search, skip]) as T

  const validatedQueryParamsString = JSON.stringify(validatedQueryParams)
  const queryParamsString = JSON.stringify(queryParams)

  useEffect(() => {
    if (validatedQueryParams) setQueryParams(validatedQueryParams)
  }, [validatedQueryParamsString, queryParamsString])

  return [validatedQueryParams, setQueryParams] as const
}

export default useValidatedQueryParams
