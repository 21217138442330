import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {Box, DialogActions, Divider} from '@mui/material'
import {commonDimensions, Typography, Button, Dialog} from 'ui-lib'

import useExistingCompanyDialogStyles from './styles'

interface ExistingCompanyDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

const ExistingCompanyDialog: FC<ExistingCompanyDialogProps> = ({isOpen, onClose, onSubmit}) => {
  const {t} = useTranslation()
  const classes = useExistingCompanyDialogStyles()

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      data-test="existing-company-dialog"
      title={t('existing-company-dialog.reversal-of-changes')}
      paperWidth={commonDimensions.dialogPaperWidth}
    >
      <>
        <Box mx={3} mb={3}>
          <Typography className={classes.subTitle} data-test="dialog-sub-title" variant="h2">
            {t('existing-company-dialog.you-are-going-to-company-admin-setting')}
          </Typography>

          <Typography className={classes.description} data-test="dialog-title">
            {t('existing-company-dialog.without-saving-data')}
          </Typography>

          <Typography className={classes.description} data-test="dialog-title">
            {t('existing-company-dialog.confirm-your-action')}
          </Typography>
        </Box>
        <Divider />
        <DialogActions className={classes.dialogButtonsWrapper}>
          <Button onClick={onClose} type="default-secondary" data-test="dialog-active-action-btn">
            {t('actions.back')}
          </Button>
          <Button onClick={() => onSubmit()} type="default-primary-yellow" data-test="dialog-active-action-btn">
            {t('actions.go-to')}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default ExistingCompanyDialog
