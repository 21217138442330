import React from 'react'

import {DataType, PaginationChangeProps, PaginationProps, SorterProps, Table, TableProps} from 'ui-lib'

import perPageParams from 'constants/pagination'
import useQueryParams from 'hooks/useQueryParams'
import {PaginationQueryParams, SortQueryParams} from 'types'

export type TableSorterProps<T extends DataType> =
  | {
      sorter?: boolean
    }
  | {
      sorter: TableProps<T>['sorter']
      setSorter: TableProps<T>['setSorter']
    }

export type TablePaginationProps =
  | {
      pagination?: boolean
    }
  | {
      pagination?: Partial<PaginationProps>
    }

export type QueryControlledTableProps<T extends DataType> = Omit<TableProps<T>, 'sorter' | 'setSorter' | 'pagination'> &
  TableSorterProps<T> &
  TablePaginationProps

const QueryControlledTable = <T extends DataType>({pagination, sorter, ...props}: QueryControlledTableProps<T>) => {
  const [queryParams, setQueryParams] = useQueryParams<PaginationQueryParams & SortQueryParams>()

  // --- pagination ---
  const handleChangePagination = React.useCallback(
    (params: PaginationChangeProps) => {
      setQueryParams(
        {
          page: params.page,
          limit: params.limit,
        },
        {merge: true},
      )
    },
    [setQueryParams],
  )

  const paginationProps = React.useMemo(() => {
    /**
     * If pagination is false, we don't render pagination
     */
    if (pagination === false) return undefined

    const queryParamsPagination: PaginationProps = {
      limit: queryParams.limit ? +queryParams.limit : perPageParams[0],
      page: queryParams.page ? +queryParams.page : 1,
      limitsValues: perPageParams,
      handleChangePagination,
      itemsCount: perPageParams[0],
    }

    /**
     * If pagination is an object, we merge it with default pagination props
     * @example
     * <QueryControlledTable pagination={{limit: 10, page: 2, handleChangePagination: () => {}}} />
     */
    if (pagination && typeof pagination === 'object') {
      return {
        ...queryParamsPagination,
        ...pagination,
      }
    }

    return queryParamsPagination
  }, [pagination, handleChangePagination])

  // --- sorting ---
  const handleSorter = React.useCallback(
    (sorterParams: SorterProps<T>) => {
      setQueryParams(
        {
          sortingOrder: sorterParams.sortType,
          sortingField: sorterParams.sortingFiled as string,
        },
        {merge: true},
      )
    },
    [setQueryParams],
  )

  const sorterProps = React.useMemo(() => {
    if (sorter === false) return undefined

    const queryParamsSorter: TableSorterProps<T> = {
      setSorter: handleSorter,
      sorter: {
        sortType: queryParams.sortingOrder,
        sortingFiled: queryParams.sortingField,
      },
    }

    if (sorter && typeof sorter === 'object') {
      return {
        ...queryParamsSorter,
        ...sorter,
      }
    }

    return queryParamsSorter
  }, [sorter, queryParams.sortingOrder, queryParams.sortingField, handleSorter])

  return <Table {...props} pagination={paginationProps} {...sorterProps} />
}

export default QueryControlledTable
