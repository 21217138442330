import {makeStyles} from '@mui/styles'
import {colors, commonDimensions} from 'ui-lib'

export const useTableStyles = makeStyles(() => ({
  drawerContainer: {
    display: 'grid',
    justifyItems: 'start',
    gridTemplateColumns: '1fr repeat(3, min-content)',
    padding: 24,
    gap: 24,
  },
  infoButton: {
    color: colors.gray500,
  },
  titleWrapper: {
    display: 'flex',
    rowGap: 8,
    alignItems: 'center',
  },
  createLink: {
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    color: colors.primaryLight,
  },
  tableRow: {
    '& [data-test="delete"]': {
      padding: '4px 7px',
    },
    '& [data-test="delete"] .deleteIcon': {
      display: 'none',
    },
    '&:hover [data-test="delete"] .deleteIcon': {
      display: 'flex',
    },
    cursor: 'pointer',
    '&:hover $idCell': {
      textDecoration: 'underline',
    },
  },
  idCell: {
    textDecoration: 'none',
    color: colors.primaryLight,
    fontSize: 16,
  },
  boldCell: {
    fontWeight: 'bold',
  },
  table: {
    maxHeight: `calc(100vh - ${commonDimensions.headerHeight} - ${commonDimensions.tabsHeight} - ${commonDimensions.pageHeaderHeight} - ${commonDimensions.borderWidth})`,
  },
  tableCombinedHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  colBlock: {
    '& div + div': {
      color: colors.gray350,
    },
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  headerClass: {
    flex: 'inherit',
  },
  logo: {
    width: 24,
    height: 24,
  },
  deleteIcon: {
    '&:hover': {
      color: colors.error,
      backgroundColor: 'rgb(237 208 209)',
    },
  },
}))
