import {createApi} from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from 'API/axiosBaseQuery'

import {cacher} from './rtkQueryCacheUtils'

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  tagTypes: [...cacher.defaultTags, 'admin', 'adminCompaniesFilterOptions', 'validateAdGroup', 'service'],
  endpoints: () => ({}),
})
