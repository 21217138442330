import React from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'

import Box from '@mui/material/Box'
import get from 'lodash/get'
import {ContentSpinner} from 'ui-lib'

import adaptCompanySettings from '../adapters/adaptCompanySettings'
import {requestFormDataFileAdapter} from '../adapters/requestFormDataFileAdapter'
import CompanyForm from '../CompanyForm'
import checkEmptyServices from '../utils/checkEmptyServices'
import checkHrsFilled from '../utils/checkHrsFilled'

import {useSnackbar} from 'hooks/useSnackbar'
import {CompanyFormValues, ServiceGroupItem} from 'pages/Company/CompanyForm/types'
import routes from 'routes'
import {useGetAdminServicesQuery} from 'store/requests'
import {getReferenceService} from 'utils/getReferenceService'

import createCompanySettings from './hooks/createCompanySettings'
import updateCompanyLogo from './hooks/updateCompanyLogo'
import updateCompanyTemplate from './hooks/updateCompanyTemplate'

const CreateCompany = () => {
  const {t} = useTranslation()
  const enqueueSnackbar = useSnackbar()
  const history = useHistory()
  const {state: locationState} = useLocation()

  const {
    data: servicesDictionary,
    error: servicesDictionaryError,
    isLoading: isServicesDictionaryLoading,
  } = useGetAdminServicesQuery()

  const [createCompany, {isLoading: isCreatingCompany}] = createCompanySettings()
  const [addLogo, {isLoading: isLogoLoading}] = updateCompanyLogo()
  const [addTemplate, {isLoading: isTemplateLoading}] = updateCompanyTemplate()

  if (servicesDictionaryError) {
    enqueueSnackbar(t('notifications.admin-services-dictionary-error'), {variant: 'error'})
  }

  const handleSubmit = async (values: CompanyFormValues) => {
    if (checkEmptyServices(values)) {
      enqueueSnackbar(t('notifications.add-services'), {variant: 'error'})
      return
    }

    if (checkHrsFilled(values)) {
      enqueueSnackbar(t('notifications.add-hr-manager'), {variant: 'error'})
      return
    }

    if (!values.services.length) {
      enqueueSnackbar(t('notifications.add-services'), {variant: 'error'})
      return
    }

    const foundReferenceService = getReferenceService(values.services)

    const adaptedValues = adaptCompanySettings(values)

    const adaptedToFormDataLogoFile = requestFormDataFileAdapter<CompanyFormValues>(values)

    const id = await createCompany(adaptedValues)

    if (id) {
      await addLogo(id, adaptedToFormDataLogoFile)

      if (foundReferenceService) {
        const adaptedToFormDataTemplateFiles = requestFormDataFileAdapter<ServiceGroupItem | undefined>(
          foundReferenceService,
        )

        await addTemplate(id, foundReferenceService.id, adaptedToFormDataTemplateFiles)
      }
    }
  }

  const handleClose = () => {
    const from = get(locationState, 'from')
    if (from) {
      history.goBack()
    } else {
      history.replace(routes.adminCompaniesList)
    }
  }

  if (!servicesDictionary || isServicesDictionaryLoading) return <ContentSpinner isLoading />

  const isLoading = isCreatingCompany || isLogoLoading || isTemplateLoading

  return (
    <ContentSpinner isLoading={isLoading} alphaBackground={0.7}>
      <Box>
        <Helmet title={t('create-company.create-title')} />
        <CompanyForm title={t('create-company.create-title')} onSubmit={handleSubmit} onClose={handleClose} />
      </Box>
    </ContentSpinner>
  )
}

export default CreateCompany
