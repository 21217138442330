import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

export const useDialogWindowStyles = makeStyles(() => ({
  scrollContainer: {
    overflowY: 'scroll',
    height: '100vh',
  },
  header: {
    padding: '8px 24px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr min-content min-content',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  container: {
    margin: '60px auto 0',
    maxWidth: '60%',
  },
  subtitle: {
    marginBottom: '40px',
  },
  input: {
    width: '100%',
    maxWidth: 420,
    margin: 'auto',
    display: 'flex',
  },
  itemContainer: {
    marginTop: 62,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(250px, 1fr))',
    boxSizing: 'border-box',
    gridGap: 24,
    padding: '40px',
    margin: 'auto',
    width: '100%',
    maxWidth: 1184,
  },
  loaderBox: {
    position: 'relative',
    display: 'flex',
    flex: 1,
  },
  noSearchBlock: {
    marginTop: 24,
  },
  contentHeader: {
    padding: '40px 0 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export const useDialogSelectStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  disabled: {
    pointerEvents: 'none',
  },
  button: {
    padding: '20px',
    borderRadius: '4px',
    border: `1px dashed ${colors.gray300}`,
    backgroundColor: colors.white,
    height: '80px',
    marginBottom: 24,
  },
}))

export const useDialogOptionsStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    padding: '24px 36px',
    borderRadius: '4px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    border: `solid 4px ${colors.white}`,
    backgroundColor: colors.white,
    cursor: 'pointer',
    maxWidth: 352,
    position: 'relative',
  },
  selected: {
    borderColor: colors.dtekYellow,
  },

  mainText: {
    color: colors.gray900,
  },
  text: {
    color: colors.gray350,
  },
  selectedDisabled: {
    border: 'none',
    cursor: 'initial',
    backgroundColor: colors.gray100,
  },
  redirectButton: {
    position: 'absolute',
    bottom: 6,
    right: 8,
  },
  wrapper: {
    position: 'relative',
  },
  buttonBaseRoot: {
    width: '100%',
    textAlign: 'left',
    padding: '24px',
    display: 'inline-block',

    '&.Mui-disabled': {
      pointerEvents: 'auto',
      cursor: 'not-allowed',

      '& .MuiPaper-root': {
        backgroundColor: colors.gray50,
      },

      '& h6': {
        color: colors.gray350,

        '&:first-child': {
          color: colors.gray500,
        },
      },
    },
  },
}))

export const useCompanyOptionsStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    boxSizing: 'border-box',
    padding: '24px 36px',
    borderRadius: '4px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    border: `solid 4px ${colors.white}`,
    backgroundColor: colors.white,
    transition: 'all 0.3s ease-in-out',
    marginBottom: '24px',
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  selected: {
    borderColor: colors.dtekYellow,
  },
  text: {
    color: colors.gray900,
  },
  label: {
    fontSize: 12,
    color: colors.gray350,
  },
  icon: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
  labelBox: {
    marginBottom: 32,
  },
  disabledHover: {
    '&:hover': {
      cursor: 'initial',
      borderColor: 'transparent',
    },
  },
}))
