import React from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'

import {Box} from '@mui/material'
import clsx from 'clsx'

import CustomRouterUserConfirmation from 'components/CustomRouterUserConfirmation'
import HeaderWrapper from 'components/HeaderWrapper'
import Tabs from 'components/Tabs'
import CompaniesList from 'pages/Company/CompaniesList'
import CreateCompany from 'pages/Company/CreateCompany'
import EditCompany from 'pages/Company/EditCompany'
import NoAccess from 'pages/NoAccess'
import NotFound from 'pages/NotFound'
import CreateService from 'pages/Service/CreateService'
import EditService from 'pages/Service/EditService'
import ServicesList from 'pages/Service/ServicesList'
import getRouteLocationWithDefaultParams from 'utils/getRouteLocationWithDefaultParams'

import useStyles from './styles'

import routes from './index'

export const AppRouter = () => {
  const classes = useStyles()
  const initialRoute = routes.adminCompaniesList
  const {pathname: initialPathname, search: initialSearch} = getRouteLocationWithDefaultParams(initialRoute)

  return (
    <Router getUserConfirmation={(message, callback) => CustomRouterUserConfirmation(message, callback)}>
      <section className={clsx(classes.mainContainer, 'main-container')}>
        <HeaderWrapper />
        <main className={classes.mainContent}>
          <Switch>
            <Route path={routes.noAccess} component={NoAccess} />
            <Route path={routes.notFound} component={NotFound} />

            <Route path={routes.editCompany} component={EditCompany} />
            <Route path={routes.createCompany} component={CreateCompany} />
            <Route path={routes.createService} component={CreateService} />
            <Route path={routes.editService} component={EditService} />

            <Route path="/">
              <Tabs />
              <Switch>
                <Route path={routes.adminCompaniesList} component={CompaniesList} />
                <Route path={routes.adminServicesList} component={ServicesList} />

                <Redirect exact from={routes.admin} to={getRouteLocationWithDefaultParams(routes.adminCompaniesList)} />
                <Redirect exact from={routes.main} to={{pathname: initialPathname, search: initialSearch}} />

                <Redirect from="*" to={routes.notFound} />
              </Switch>
            </Route>
            <Redirect from="*" to={routes.notFound} />
          </Switch>
        </main>
        <Box id="pageFooter" />
      </section>
    </Router>
  )
}
