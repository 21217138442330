import {FileObject} from 'components/UploadingFile/UploadingFile'
import {ServicesEnum} from 'constants/services'
import {AdGroupItem} from 'store/services'
import {Id} from 'types'

export interface ServiceFormValues {
  id?: Id
  hrServiceType?: ServicesEnum | null
  name: string
  englishName: string
  isLimitedByAD: boolean
  link?: string
  englishLink?: string
  logo?: FileObject[] | null
  adGroups?: AdGroupItem[] | null
  resourceType: ResourceTypeEnum
}

export enum ResourceTypeEnum {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}
