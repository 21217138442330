import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import get from 'lodash/get'

import userApi from 'API/user'
import {RootState} from 'store'
import {User} from 'types'

export const getUserNameSelector = (state: RootState): string => get(state, 'account.user.fullName', '')

export const loadUserData = createAsyncThunk('account/getUserData', async (args, {rejectWithValue}) => {
  try {
    const userData = await userApi.getMe()

    return {
      user: userData,
    }
  } catch (err) {
    const error = err as {payload: object; response?: {status: string}}
    return rejectWithValue({
      ...error.payload,
      status: error.response?.status,
    })
  }
})

interface AccountState {
  user: User | null
}

const initialState: AccountState = {
  user: null,
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadUserData.fulfilled, (state: AccountState, action: PayloadAction<{user: User}>) => {
        state.user = action.payload.user
      })
      // and provide a default case if no other handlers matched
      .addDefaultCase(() => {})
  },
})

export default accountSlice.reducer
