import React, {FC} from 'react'

import {AutocompleteRenderOptionState} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import clsx from 'clsx'
import {Typography} from 'ui-lib'

import {IOption} from 'types'

import {useHrOptionStyles} from './styles'

type HrAutocompleteOptionProps = {
  option: IOption
  state: AutocompleteRenderOptionState
}
const HrAutocompleteOption: FC<HrAutocompleteOptionProps> = props => {
  const classes = useHrOptionStyles()
  return (
    <ListItem
      {...props}
      key={props?.option?.value}
      className={classes.listItem}
      data-test={`option-${props?.option?.value}`}
    >
      <Checkbox
        size="small"
        checked={props?.state?.selected}
        data-test="option-checkbox"
        data-value={props?.state?.selected}
      />
      <ListItemText>
        <Typography className={classes.label} data-test="option-label">
          {props?.option?.label}
        </Typography>
        <Typography className={clsx(classes.label, classes.email)} data-test="option-email">
          {props?.option?.email}
        </Typography>
      </ListItemText>
    </ListItem>
  )
}

export default HrAutocompleteOption
