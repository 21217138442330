import React from 'react'

import {DefaultFile} from 'components/UploadingFile/UploadingFile'
import {defaultFileToUrl} from 'components/UploadingFile/utils'

import {useServiceIconStyles} from './serviceIconStyles'

const ServiceIcon = ({logo, isActive}: {logo?: DefaultFile; isActive: boolean}) => {
  const classes = useServiceIconStyles({isActive})

  const url = defaultFileToUrl(logo)
  if (!url) return null

  return <img className={classes.icon} src={url} alt={logo?.fileDownloadName} />
}

export default ServiceIcon
