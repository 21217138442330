import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

const useAdStyles = makeStyles(() => ({
  inputContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'baseline',
    marginTop: '20px',
  },
  tagsContainer: {
    margin: '16px 0',
    display: 'flex',
    gap: 10,
    flexWrap: 'wrap',
  },
  button: {
    border: `1px solid ${colors.gray300}`,
    height: 44,
    padding: '8px 18px',
    '& h6': {
      fontWeight: 'normal',
    },
  },
  tag: {
    height: '32px',
  },
}))

export default useAdStyles
