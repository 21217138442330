import React from 'react'
import ReactDOM from 'react-dom'

import DialogComponent from './DialogComponent'

const CustomRouterUserConfirmation = (message: string, callback: (ok: boolean) => void) => {
  const container = document.createElement('div')
  container.setAttribute('custom-confirmation-navigation', '')
  document.body.appendChild(container)

  const closeModal = (callbackState: boolean) => {
    ReactDOM.unmountComponentAtNode(container)
    document.body.removeChild(container)
    callback(callbackState)
  }

  ReactDOM.render(<DialogComponent closeModal={closeModal} message={message} />, container)
}
export default CustomRouterUserConfirmation
