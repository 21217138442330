import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

const useServiceConfigCardStyles = makeStyles(() => ({
  serviceCard: {
    padding: '16px 12px 16px 24px',
    border: `1px solid ${colors.gray60}`,
    borderRadius: 4,
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  serviceCardRow: {
    display: 'grid',
    gridTemplateColumns: '0.3fr 1fr',
    alignItems: 'center',
    minHeight: 48,
  },
  serviceCardTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 700,
    gap: 8,
  },

  notActive: {
    color: colors.gray300,
  },
}))

export default useServiceConfigCardStyles
