import React from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {generatePath, useHistory} from 'react-router-dom'

import Add from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import clsx from 'clsx'
import get from 'lodash/get'
import omit from 'lodash/omit'
import {Button, ContentSpinner, FilterButton, FiltersDrawer, NoSearchData, PageHeader} from 'ui-lib'

import QueryControlledTable from 'components/QueryControlledTable'
// utils & constants
import defaultPerPageParams from 'constants/pagination'
import {useSnackbar} from 'hooks/useSnackbar'
import useToggle from 'hooks/useToggle'
import useValidatedQueryParams from 'hooks/useValidatedQueryParams'
import {useTableStyles} from 'pages/Company/CompaniesList/ListStyles'
import routes from 'routes'
import {useGetAdminCompaniesFiltersQuery, useGetAdminCompaniesListQuery} from 'store/requests'
import {getAdaptedFilterOptions} from 'utils/adapters/filters'
import {hasActiveFilters} from 'utils/hasActiveFilters'

import configColumns from './CompaniesListTableConfig'
import RequestFiltersForm, {RequestFiltersFormValues} from './components/RequestFiltersForm'
import {getQueryParamsConfig} from './getQueryParamsConfig'
import {CompanyItem, RequestsListPageParams} from './types'
import {filtersFormInitialStataAdapter} from './utils'

const parameters: Array<keyof RequestsListPageParams> = ['hrIds', 'companyIds']

export const defaultQueryParams: RequestsListPageParams = {
  limit: '10',
  page: '1',
  sortingOrder: 'desc',
  sortingField: 'id',
  entityType: 'any',
}

const CompaniesList: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()

  const {
    data: filterOptions,
    isLoading: isFilterOptionsLoading,
    isFetching: isFilterOptionsFetching,
    isError: isFilterOptionsError,
  } = useGetAdminCompaniesFiltersQuery()

  const adaptedFilterOptions = getAdaptedFilterOptions(filterOptions)
  const queryParamsConfig = getQueryParamsConfig(adaptedFilterOptions)
  const [queryParams, setQueryParams] = useValidatedQueryParams<RequestsListPageParams>(
    {
      defaultQueryParams,
      queryParamsConfig,
    },
    {skip: !filterOptions},
  )

  const [isDrawerVisible, , closeDrawer, toggleDrawer] = useToggle()
  const enqueueSnackbar = useSnackbar()
  const withFilters =
    hasActiveFilters(queryParams || {}, parameters) ||
    (Boolean(queryParams?.entityType) && queryParams?.entityType !== 'any')
  const limit = Number(get(queryParams, 'limit', defaultPerPageParams[0]))

  const page = Number(get(queryParams, 'page'))

  // ****** list handlers ******
  const classes = useTableStyles({})
  const {
    data: tableData,
    isLoading,
    isFetching,
    isError: isListError,
    isSuccess,
  } = useGetAdminCompaniesListQuery(
    {
      ...(queryParams?.entityType === 'archived' && {isArchived: true}),
      ...(queryParams?.entityType === 'active' && {isArchived: false}),
      ...omit(queryParams || {}, ['entityType']),
      limit,
      offset: (page - 1) * limit,
    },
    {skip: !queryParams},
  )

  if (isListError) {
    enqueueSnackbar(t('administration.companies-list-error'), {variant: 'error'})
  }
  if (isFilterOptionsError) {
    enqueueSnackbar(t('notifications.request-list-filters-error'), {variant: 'error'})
  }

  const handleRequestFilterFormSubmit = (formState: RequestFiltersFormValues) => {
    setQueryParams({
      hrIds: formState.hrIds?.map((o: {value: number}) => o.value),
      companyIds: formState.companyIds?.map((o: {value: number}) => o.value),
      entityType: formState.entityType,
    })
    closeDrawer()
  }

  const handleRequestFilterFormReset = () => {
    setQueryParams(
      {
        hrIds: null,
        companyIds: null,
        entityType: defaultQueryParams.entityType,
      },
      {merge: true},
    )
  }

  return (
    <>
      <Helmet title={t('administration.title')} />

      <PageHeader title={t('administration.title')}>
        <Button
          className={classes.addButton}
          startIcon={<Add />}
          type="cart-secondary"
          onClick={() => history.push(routes.createCompany)}
          data-test="create-company-btn"
        >
          {t('administration.add-company')}
        </Button>
        <FilterButton
          tooltip={t('administration.filters')}
          onClick={toggleDrawer}
          hasActiveFilters={withFilters}
          filterOpened={isDrawerVisible}
          disabled={isFilterOptionsLoading}
        />
      </PageHeader>

      <Box className={classes.tableWrapper}>
        <QueryControlledTable<CompanyItem>
          className={classes.table}
          columns={configColumns()}
          data={tableData?.items ?? []}
          rowCount={tableData?.totalCount ?? 0}
          loading={isLoading || isFetching || isFilterOptionsLoading || isFilterOptionsFetching}
          onRow={row => ({
            className: clsx(classes.tableRow, row.isArchived && classes.isArchived),
            dataTest: `company-list-row-${row.id}`,
            onClick: () => {
              const state = {from: queryParams}
              history.push(generatePath(routes.editCompany, {id: row.id}), state)
            },
          })}
          pagination={{
            itemsCount: tableData?.totalCount as number,
          }}
          noDataContent={
            <NoSearchData {...(withFilters && {subtitles: [t('no-search-data.description-with-filters')]})} />
          }
          isDataLoaded={isSuccess || isListError}
        />
        <FiltersDrawer open={isDrawerVisible}>
          <ContentSpinner isLoading={isLoading} alphaBackground={0.7} viewPortCentered={false}>
            <RequestFiltersForm
              hasActiveFilters={withFilters}
              options={adaptedFilterOptions}
              onSubmit={handleRequestFilterFormSubmit}
              onReset={handleRequestFilterFormReset}
              initialValues={filtersFormInitialStataAdapter(queryParams || {})}
              hasSelectedItems={false}
            />
          </ContentSpinner>
        </FiltersDrawer>
      </Box>
    </>
  )
}

export default CompaniesList
