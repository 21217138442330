import React from 'react'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {Box, IconButton} from '@mui/material'
import clsx from 'clsx'
import {Typography} from 'ui-lib'

import {IDialogOption} from 'pages/Company/types'
import {Id} from 'types'

import {useEmployeeOptionsStyles} from './styles'

export interface DialogOptionProps extends IDialogOption {
  onClick?: () => void
  selected?: boolean
  onIconClick: (id?: Id) => void
  disabledHover?: boolean
  disabled?: boolean
}

const EmployeeSelectedOption: React.FC<DialogOptionProps> = ({
  personnelNumber,
  location,
  workPhone,
  position,
  name,
  email,
  onClick,
  onIconClick,
  selected,
  disabledHover,
  disabled,
  id,
}) => {
  const classes = useEmployeeOptionsStyles()

  return (
    <Box
      onClick={onClick}
      className={clsx(classes.container, selected && classes.selected, disabledHover && classes.disabledHover)}
    >
      {name && (
        <Typography variant="subtitle2" className={classes.mainText} data-test="employee-name">
          {name}
        </Typography>
      )}
      {email && (
        <Typography variant="subtitle2" className={classes.text} data-test="employee-email">
          {email}
        </Typography>
      )}
      {position && (
        <Typography variant="subtitle2" className={classes.text} data-test="employee-position">
          {position}
        </Typography>
      )}

      {workPhone && (
        <Typography variant="subtitle2" className={classes.text} data-test="employee-phone">
          {workPhone}
        </Typography>
      )}

      {location && (
        <Typography variant="subtitle2" className={classes.text} data-test="employee-location">
          {location}
        </Typography>
      )}

      {personnelNumber && (
        <Typography variant="subtitle2" className={classes.text} data-test="employee-personnel-number">
          {personnelNumber}
        </Typography>
      )}

      <Box className={classes.checkboxContainer}>
        {!disabled && (
          <IconButton
            onClick={e => {
              e.stopPropagation()
              onIconClick(id)
            }}
            className={classes.icon}
            aria-label="delete"
            data-test="delete-employee"
            component="label"
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default EmployeeSelectedOption
