import React from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {Box} from '@mui/material'

import {ReactComponent as NotFoundImage} from 'assets/404.svg'
import routes from 'routes/routes'

import useStyles from './styles'

const NotFound: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Helmet title={t('not-found-page.title')} />

      <Box className={classes.content} data-test="not-found-page">
        <NotFoundImage />
        <Box className={classes.label} data-test="not-found-page-title">
          {t('not-found-page.title')}
        </Box>
        <Box className={classes.subLabel} data-test="not-found-page-subtitle">
          {t('not-found-page.description-prefix')}

          <Link to={{pathname: routes.main}} data-test="link-to-home-page">
            <span data-test="linkToMainPage" className={classes.toHomePageLink}>
              {t('not-found-page.main-page')}
            </span>
          </Link>
        </Box>
      </Box>
    </>
  )
}

export default NotFound
