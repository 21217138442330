import {useTranslation} from 'react-i18next'

import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'

import {Errors} from 'constants/errors'

import {useSnackbar} from './useSnackbar'

export const errorSwitch: Record<Errors, any> = {
  // TODO Add errors translations
  // export const errorSwitch: Record<Errors, Normalize<TranslationUA>> = {

  [Errors.BAD_REQUEST]: 'notifications.bad-request',
  [Errors.VALIDATION_ERROR]: '',
  [Errors.UNAUTHORIZED]: '',
  [Errors.UNKNOWN_ERROR]: '',
  [Errors.ENTRY_NOT_FOUND]: '',
  [Errors.COMPANY_CONFIG_EXISTS]: 'notifications.company-config-exists',
  [Errors.EXIST_SERVICE]: 'notifications.service-config-exists',
  [Errors.EMPTY_SERVICE]: 'notifications.add-services',
  [Errors.EXISTING_SERVICE_NAME]: 'notifications.existing-service-name',
}

export const useErrors = () => {
  const {t} = useTranslation()
  const enqueueSnackbar = useSnackbar()

  return (errorCode: keyof Record<Errors, string>, defaultError?: string) => {
    const getErrorCode = get(errorSwitch, errorCode)
    enqueueSnackbar(t(getErrorCode || defaultError || 'actions.common-error'), {variant: 'error'})
  }
}

export const useGroupErrors = () => {
  const {t} = useTranslation()
  const enqueueSnackbar = useSnackbar()

  return (errors: any) => {
    if (!isArray(errors)) {
      enqueueSnackbar(t('actions.unknown-error'), {variant: 'error'})
      return
    }

    const groupedErrorsByCode = groupBy(errors, ({code}) => code)
    const groupedErrorsEntries = Object.entries(groupedErrorsByCode)
    const groupedErrorsTemplates = groupedErrorsEntries.reduce((acc: any[], [code, id]) => {
      const ids = id.map(({id}) => id)

      const prepareValue = {
        code,
        ids,
      }

      return [...acc, prepareValue]
    }, [])

    const errorMessage = groupedErrorsTemplates
      .map(({code, ids}) => `${t(get(errorSwitch, code) || 'actions.unknown-error')}: Ids: ${ids}`)
      .join('; ')

    enqueueSnackbar(errorMessage, {variant: 'error'})
  }
}
