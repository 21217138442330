import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'

import {combineReducers, configureStore} from '@reduxjs/toolkit'

import account from './account'
import {api} from './api'

const reducer = combineReducers({
  account,
  [api.reducerPath]: api.reducer,
})
const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type DispatchFunction = () => AppDispatch

export const useAppDispatch: DispatchFunction = useDispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
