import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'

import compact from 'lodash/compact'
import {TabsWithCounters} from 'ui-lib'

import routes from 'routes'

const TabsNav = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const options = [
    {
      id: routes.adminCompaniesList,
      title: t('tabs.companies'),
    },
    {
      id: routes.adminServicesList,
      title: t('tabs.services'),
    },
  ]

  const handleTabChange = (tabId: string) => {
    if (tabId !== location.pathname) {
      history.push(tabId)
    }
  }

  return <TabsWithCounters value={location.pathname} options={compact(options)} onChange={handleTabChange} />
}

export default TabsNav
