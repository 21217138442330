import React from 'react'
import {Link, type LinkProps} from 'react-router-dom'

interface TableLinkProps extends LinkProps {}

const TableLink: React.FC<TableLinkProps> = ({onClick, ...props}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
    if (onClick) onClick(e)
  }

  return <Link onClick={handleClick} {...props} />
}

export default TableLink
