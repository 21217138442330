import * as Yup from 'yup'

import {ResourceTypeEnum} from './types'

export const getServiceValidationSchema = (resourceType?: ResourceTypeEnum) =>
  Yup.object().shape({
    hrServiceType: Yup.string()
      .max(256)
      .nullable()
      .when([], {
        is: () => resourceType === ResourceTypeEnum.INTERNAL,
        then: schema => schema.required('validation.required'),
        otherwise: schema => schema,
      }),
    name: Yup.string().max(256).required('validation.required'),
    englishName: Yup.string().required('validation.required'),
    link: Yup.string()
      .max(256)
      .nullable()
      .when([], {
        is: () => resourceType === ResourceTypeEnum.EXTERNAL,
        then: schema => schema.required('validation.required'),
        otherwise: schema => schema,
      }),
    englishLink: Yup.string()
      .max(256)
      .nullable()
      .when([], {
        is: () => resourceType === ResourceTypeEnum.EXTERNAL,
        then: schema => schema.required('validation.required'),
        otherwise: schema => schema,
      }),
    logo: Yup.array().min(1).nullable().required('validation.required'),
    isLimitedByAD: Yup.boolean().nullable(),
    adGroups: Yup.array()
      .nullable()
      .when('isLimitedByAD', {
        is: true,
        then: schema => {
          return schema.min(1).required('validation.required')
        },
        otherwise: schema => schema,
      }),
    resourceType: Yup.string().max(256).nullable().required('validation.required'),
  })
