import React from 'react'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'

import DocumentItem from './DocumentItem'
import {FileObject} from './UploadingFile'
import {useUploadingFile} from './styles'

export interface FileListProps {
  files: FileObject[]
  handleDelete?: (id: string) => void
  canSeeViewIcon?: boolean
  canSeeDeleteIcon?: boolean
  canSeeDownloadIcon?: boolean
  disabledFileIds?: string[]
}

const FileList: React.FC<FileListProps> = ({
  files,
  handleDelete,
  canSeeViewIcon = true,
  canSeeDownloadIcon = false,
  canSeeDeleteIcon = true,
  disabledFileIds,
}) => {
  const classes = useUploadingFile({})
  const canSeeViewButton = canSeeViewIcon
  const canSeeDownloadButton = canSeeDownloadIcon
  const canSeeDeleteButton = canSeeDeleteIcon

  const handleDownload = (file: File) => {
    const url = window.URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', file.name)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const handlePreview = (file: File) => {
    const url = window.URL.createObjectURL(file)
    window.open(url, '_blank')
  }

  return (
    <ul className={classes.documentList}>
      {files?.map(fileObj => {
        const canSeeDisabled = !disabledFileIds?.includes(fileObj.id)

        return (
          <DocumentItem
            key={fileObj.id}
            fileName={fileObj.file.name}
            controls={[
              {
                label: 'Preview',
                Icon: RemoveRedEyeOutlinedIcon,
                onClick: () => handlePreview(fileObj.file),
                canSee: canSeeViewButton,
              },
              {
                label: 'Download',
                Icon: FileDownloadOutlinedIcon,
                onClick: () => handleDownload(fileObj.file),
                canSee: canSeeDownloadButton,
              },
              {
                label: 'Delete',
                Icon: DeleteOutlineOutlinedIcon,
                onClick: () => handleDelete?.(fileObj.id),
                canSee: canSeeDeleteButton && canSeeDisabled,
              },
            ]}
          />
        )
      })}
    </ul>
  )
}

export default FileList
