import get from 'lodash/get'

import {Errors} from 'constants/errors'
import {useErrors} from 'hooks/useErrors'
import {useDeleteServiceMutation} from 'store/services'

const useDeleteService = () => {
  const [deleteService, {isLoading}] = useDeleteServiceMutation()
  const handleErrors = useErrors()

  const handleDelete = async (id: number) => {
    try {
      await deleteService({id}).unwrap()
    } catch (error) {
      const errorCode = get(error, 'data.code') as unknown as Errors

      handleErrors(errorCode, 'notifications.bad-request')
    }
  }

  return [handleDelete, {isDeleting: isLoading}] as const
}

export default useDeleteService
