import compact from 'lodash/compact'

import {CompanyFormValues, ServiceGroupItem} from '../CompanyForm/types'

import {ServicesEnum} from 'constants/services'
import {CompanyServicesOutDto, CompanySettingsItem, HrGroupItem, ReceivingMethodEnum} from 'store/requests'

const adaptCompanySettings = (values: CompanyFormValues, isEdit?: boolean): CompanyServicesOutDto => {
  const adaptedServices: CompanySettingsItem[] = []

  function adaptService(service: ServiceGroupItem, hrGroup: HrGroupItem | null = null) {
    const isExternal = service.hrServiceType === ServicesEnum.EXTERNAL

    if (!service?.serviceFromGroup) {
      const adaptedService = {
        id: service.id || 0,
        hrServiceType: service.hrServiceType || '',
        isActive: isExternal ? true : service.isActive || false,
        ...(!isExternal && {
          isLimitedAccess: service?.isLimitedAccess,
        }),
        ...(!isExternal && {
          isCheckListEnabled: service?.isCheckListEnabled,
        }),
        ...(!isExternal && {
          isDismissalRequestEnabled: service?.isDismissalRequestEnabled,
        }),
        ...(!isExternal && {
          isDocumentSignViaDijaEnabled: service?.isDocumentSignViaDijaEnabled,
        }),
        ...(!isExternal && {
          isEnableCompensation: service?.isEnableCompensation,
        }),
        ...(!isExternal && {
          receivingMethods: compact([
            service.isEnableAddressReceivingMethod && ReceivingMethodEnum.ADDRESS,
            service.isEnableDigitalReceivingMethod && ReceivingMethodEnum.DIGITAL,
            service.isEnableEReferenceReceivingMethod && ReceivingMethodEnum.E_REFERENCE,
            ReceivingMethodEnum.OFFICE,
          ]),
        }),
        ...(!isExternal && {
          hrIds: service?.hrs?.map(hr => hr.id) || [],
        }),
        name: service.name || '',
        englishName: service.englishName || '',
        sequence: service.sequence || 0,
        ...(hrGroup && {
          hrGroup: {
            ...(isEdit && {
              id: hrGroup.id,
            }),
            name: hrGroup.name || '',
            englishName: hrGroup.englishName || '',
            sequence: hrGroup.sequence || 0,
          },
        }),
      }

      adaptedServices.push(adaptedService)
    }

    if (service.isGroup && service.serviceFromGroup) {
      service.serviceFromGroup.forEach((groupService, index) =>
        adaptService(
          {...groupService, sequence: index + 1},
          {
            ...(isEdit && {
              id: service?.id,
            }),
            name: service.name || '',
            englishName: service.englishName || '',
            sequence: service.sequence,
          },
        ),
      )
    }
  }

  values.services.forEach((service, index) => adaptService({...service, sequence: index + 1}))

  return {
    isArchived: values.isArchived,
    companyId: values.companyInfo?.id || 0,
    services: adaptedServices,
  }
}

export default adaptCompanySettings
