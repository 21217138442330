import React from 'react'
import {useTranslation} from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import {Box} from '@mui/material'
import clsx from 'clsx'
import {Button, Typography} from 'ui-lib'

import {useUploadStyles} from './styles'

export interface UploadProps {
  inputName?: string
  uploadButtonText: string
  dragAndDrop?: boolean
  accept?: string
  multiple?: boolean
  onChange?: (file: File[]) => void
  disabled?: boolean
  error?: string
  showError?: string | false
  isRequired?: boolean
  customRequiredText?: string
}

const Upload: React.FC<UploadProps> = ({
  onChange,
  disabled,
  uploadButtonText,
  error,
  showError,
  isRequired,
  customRequiredText,
  dragAndDrop = false,
  inputName,
  ...props
}) => {
  const {t} = useTranslation()
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [dragActive, setDragActive] = React.useState(false)
  const classes = useUploadStyles({active: dragActive, error: Boolean(error)})

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && onChange) {
      onChange(Array.from(e.dataTransfer.files))
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && onChange) {
      onChange(Array.from(e.target.files))
      e.target.value = ''
    }
  }

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <div className={classes.container} onDragEnter={e => dragAndDrop && handleDrag(e)}>
      <input
        ref={inputRef}
        type="file"
        name={inputName}
        id="input-file-upload"
        onChange={handleChange}
        hidden
        {...props}
      />

      {!disabled && (
        <div className={classes.label} id="label-file-upload">
          <Box className={classes.field}>
            <Button
              className={classes.addFileBtn}
              htmlType="button"
              type="cart-secondary"
              startIcon={<AddIcon />}
              data-test="add-file-btn"
              component="label"
              onClick={handleButtonClick}
              disabled={disabled}
            >
              {uploadButtonText}
            </Button>

            {isRequired && (
              <Typography variant="subtitle2" className={clsx(classes.requiredText, showError && classes.errorColor)}>
                {error || customRequiredText || t('validation.required')}
              </Typography>
            )}
          </Box>
        </div>
      )}

      {dragActive && (
        <div
          className={classes.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  )
}

export default Upload
