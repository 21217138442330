import {ServicesEnum} from 'constants/services'
import {ServiceGroupItem} from 'pages/Company/CompanyForm/types'

const checkServicesReference = (service: ServicesEnum) => service === ServicesEnum.REFERENCES

export const getReferenceService = (services: ServiceGroupItem[]) =>
  services.reduce<ServiceGroupItem | undefined>((acc, service) => {
    if (service.isGroup) {
      const groupService = service.serviceFromGroup.find(serviceGroup =>
        checkServicesReference(serviceGroup.hrServiceType),
      )
      return groupService
    }

    if (checkServicesReference(service.hrServiceType)) {
      return service
    }

    return acc
  }, undefined)
