/**
 * Default tags used by the cacher helpers
 */
const defaultTags = ['UNAUTHORIZED', 'UNKNOWN_ERROR'] as const
type DefaultTags = (typeof defaultTags)[number]

/**
 * An individual cache item
 */
export type CacheItem<T, ID> = {type: T; id: ID}

/**
 * A list of cache items, including a LIST entity cache
 */
export type CacheList<T, ID> = (CacheItem<T, 'LIST'> | CacheItem<T, ID> | DefaultTags)[]

type InnerProvidesNestedList<T> = <Results extends {items: {id: unknown}[]}>(
  results: Results | undefined,
) => CacheList<T, Results['items'][number]['id']>

export const providesNestedList =
  <T extends string>(type: T): InnerProvidesNestedList<T> =>
  results => {
    // is result available?
    if (results) {
      // successful query
      return [{type, id: 'LIST'}, ...results.items.map(({id}) => ({type, id} as const))]
    }
    // Received an error, include an error cache item to the cache list
    return [{type, id: 'LIST'}, 'UNKNOWN_ERROR']
  }

/**
 * Utility helpers for common provides/invalidates scenarios
 */
export const cacher = {
  // TODO USE CACHER FOR REQUESTS.TS
  defaultTags,
  providesNestedList,
}
