import omitBy from 'lodash/omitBy'

import {ServiceFormValues} from '../ServiceForm/types'

export const adaptServiceData = (serviceFormValues: ServiceFormValues) => {
  const values = omitBy(serviceFormValues, value => !value) as ServiceFormValues

  return {
    ...values,
    isLimitedByAD: serviceFormValues.isLimitedByAD,
    ...(serviceFormValues.adGroups && {adGroups: serviceFormValues?.adGroups?.map(value => value.id)}),
  }
}
