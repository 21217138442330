import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

export const useAddButtonStyles = makeStyles(() => ({
  disabled: {
    pointerEvents: 'none',
  },
  button: {
    padding: '20px',
    borderRadius: '4px',
    border: `1px dashed ${colors.gray300}`,
    backgroundColor: colors.white,
    height: '40px',
    marginBottom: 24,
    width: '100%',
  },
}))
