import React from 'react'

const useToggle = (initialValue = false) => {
  const [value, setValue] = React.useState(initialValue)

  const {toggle, on, off} = React.useMemo(
    () => ({
      toggle: () => setValue(prev => !prev),
      on: () => setValue(true),
      off: () => setValue(false),
    }),
    [],
  )

  return [value, on, off, toggle] as const
}

export default useToggle
