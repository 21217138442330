import {CompanyFormValues} from '../CompanyForm/types'

const checkEmptyServices = (values: CompanyFormValues): boolean => {
  return (
    !values.services.length ||
    values.services.some(service => {
      if (service.serviceFromGroup) {
        return !service?.serviceFromGroup?.length
      }
      return false
    })
  )
}

export default checkEmptyServices
