import {makeStyles} from '@mui/styles'

interface StylesProps {
  isActive?: boolean
}

export const useServiceIconStyles = makeStyles<never, StylesProps>(() => ({
  icon: {
    width: 24,
    height: 24,
    opacity: ({isActive}) => (isActive ? 1 : 0.3),
    transition: 'color 0.3s',
  },
}))
