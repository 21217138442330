import qs from 'qs'

import {defaultQueryParams as adminDefaultQueryParams} from 'pages/Company/CompaniesList/CompaniesList'
import {getQueryParamsConfig as getAdminCompaniesQueryParamsConfig} from 'pages/Company/CompaniesList/getQueryParamsConfig'
import routes from 'routes'
import getQueryParamsSortingFunction from 'utils/getQueryParamsSortingFunction'

const getDefaultRouteParams = (route: string) => {
  const params = {
    [routes.adminCompaniesList]: adminDefaultQueryParams,
  }
  return params[route]
}

const getQueryParamsConfigFunc = (route: string) => {
  const params = {
    [routes.adminCompaniesList]: getAdminCompaniesQueryParamsConfig,
  }
  return params[route]
}
const getRouteLocationWithDefaultParams = (route: string) => {
  const defaultListParams = getDefaultRouteParams(route)
  const queryParamsConfigFunc = getQueryParamsConfigFunc(route)
  const queryParamsConfig = typeof queryParamsConfigFunc === 'function' ? queryParamsConfigFunc({}) : {}
  const sortingFunc = getQueryParamsSortingFunction(queryParamsConfig)

  const queryParamsString = qs.stringify(defaultListParams, {
    skipNulls: true,
    sort: sortingFunc,
    arrayFormat: 'comma',
  })

  return {pathname: route, search: decodeURIComponent(queryParamsString)}
}

export default getRouteLocationWithDefaultParams
