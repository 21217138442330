import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

interface StyleProps {
  error?: boolean
  active?: boolean
}

const getBorderColor = (props: StyleProps) => {
  if (props.error) {
    return colors.error
  }

  if (props.active) {
    return colors.primaryLight
  }

  return colors.gray300
}

export const useUploadStyles = makeStyles<void, StyleProps>(() => ({
  container: {
    position: 'relative',
  },
  field: props => ({
    border: `1px dashed ${getBorderColor(props)}`,
    borderRadius: 6,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: props.active ? colors.lightSelection : colors.white,
  }),
  addFileBtn: {
    color: props => (props.error ? colors.error : colors.primaryLight),
    borderRadius: 4,
    '& .MuiButton-startIcon > svg': {
      fontSize: 24,
    },
  },
  dragFileElement: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 6,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  requiredText: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 'normal',
    color: colors.gray350,
    marginTop: 3,
  },
  errorColor: {
    color: colors.error,
  },
}))
