import {useTranslation} from 'react-i18next'
import {generatePath, useHistory} from 'react-router-dom'

import get from 'lodash/get'

import {Errors} from 'constants/errors'
import {useErrors} from 'hooks/useErrors'
import {useSnackbar} from 'hooks/useSnackbar'
import routes from 'routes'
import {useCreateServiceMutation} from 'store/services'

const UseCreateService = () => {
  const {t} = useTranslation()
  const handleErrors = useErrors()
  const enqueueSnackbar = useSnackbar()
  const history = useHistory()
  const [createService, {isLoading}] = useCreateServiceMutation()

  const handleCreateService = async (values: FormData) => {
    try {
      const {id} = await createService(values).unwrap()
      enqueueSnackbar(t('notifications.service-create-success'), {variant: 'success'})

      history.push(generatePath(routes.adminServicesList, {id}))
    } catch (error) {
      const errorCode = get(error, 'data.code') as unknown as Errors

      handleErrors(errorCode, 'notifications.bad-request')
    }
  }

  return [handleCreateService, {isLoading}] as const
}

export default UseCreateService
