import React from 'react'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {Box, IconButton} from '@mui/material'
import {FormTextField as TextField} from 'ui-lib/dist/components/TextField'

import useOrderControlsStyles from './styles'

interface OrderControlsProps {
  handleMoveToTopSequence: () => void
  handleMoveToBottomSequence: () => void
  isInGroup: boolean
  serviceIndex: number
  serviceInGroupIndex?: number
  valuesLength: number
}

const OrderControls: React.FC<OrderControlsProps> = ({
  handleMoveToTopSequence,
  handleMoveToBottomSequence,
  isInGroup,
  serviceIndex,
  serviceInGroupIndex,
  valuesLength,
}) => {
  const classes = useOrderControlsStyles()
  const index = isInGroup && serviceInGroupIndex !== undefined ? serviceInGroupIndex : serviceIndex
  const sequenceIndex = index + 1
  const sequenceIndexInGroup = serviceInGroupIndex! + 1

  return (
    <Box className={classes.orderContainer}>
      <Box className={classes.orderWrapper}>
        <IconButton
          disabled={index === 0}
          data-test="chevron-top"
          onClick={handleMoveToTopSequence}
          className={classes.orderIconTop}
          size="small"
        >
          <ChevronLeftIcon />
        </IconButton>

        <TextField
          disabled
          className={classes.orderInput}
          name={
            isInGroup
              ? `services.${serviceIndex}.serviceFromGroup.${serviceInGroupIndex}.sequence`
              : `services.${index}.sequence`
          }
          value={isInGroup ? `${serviceIndex + 1}.${sequenceIndexInGroup}` : sequenceIndex}
          inputProps={{
            maxLength: 256,
            'data-test': 'sequence',
            readOnly: true,
          }}
        />

        <IconButton
          disabled={index === valuesLength - 1}
          data-test="chevron-bottom"
          onClick={handleMoveToBottomSequence}
          className={classes.orderIconBottom}
          size="small"
        >
          <ChevronLeftIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default OrderControls
