import React from 'react'
import {useTranslation} from 'react-i18next'

import {Box, Tooltip} from '@mui/material'
import ButtonBase from '@mui/material/ButtonBase'
import clsx from 'clsx'
import {Button, Typography} from 'ui-lib'

import {useDialogOptionsStyles} from '../DialogSelect/styles'

import {IDialogOption} from 'pages/Company/types'

export interface DialogOptionProps {
  onRedirect?: () => void
  onClick?: () => void
  selected?: boolean
  selectedDisabled?: boolean
  value: IDialogOption
}

const DialogOption: React.FC<DialogOptionProps> = ({value, onClick, selected, selectedDisabled, onRedirect}) => {
  const classes = useDialogOptionsStyles()
  const {t} = useTranslation()
  const tooltipMessage = value.rootId
    ? t('create-company.disabled-tooltip')
    : t('create-company.disabled-tooltip-employee')

  return (
    <Box className={classes.wrapper}>
      <Tooltip
        onClick={!selectedDisabled ? onClick : undefined}
        className={clsx(selectedDisabled && classes.selectedDisabled, classes.container, selected && classes.selected)}
        title={selectedDisabled ? tooltipMessage : ''}
      >
        <ButtonBase
          disabled={!!value.rootId && !!value.companyConfigId}
          data-test="employee-btn"
          classes={{root: clsx(classes.buttonBaseRoot)}}
        >
          {value.name && (
            <Typography variant="subtitle2" className={classes.mainText}>
              {value.name}
            </Typography>
          )}
          {value.rootId && (
            <Typography variant="subtitle2" className={classes.text}>
              {value.id}
            </Typography>
          )}
          {value.email && (
            <Typography variant="subtitle2" className={classes.text}>
              {value.email}
            </Typography>
          )}
          {value.position && (
            <Typography variant="subtitle2" className={classes.text}>
              {value.position}
            </Typography>
          )}
          {value.personnelNumber && (
            <Typography variant="subtitle2" className={classes.text}>
              {value.personnelNumber}
            </Typography>
          )}
          {value.workPhone && (
            <Typography variant="subtitle2" className={classes.text}>
              {value.workPhone}
            </Typography>
          )}
          {value.location && (
            <Typography variant="subtitle2" className={classes.text}>
              {value.location}
            </Typography>
          )}
        </ButtonBase>
      </Tooltip>

      {value.companyConfigId && (
        <Button
          type="cart-secondary"
          className={classes.redirectButton}
          onClick={() => {
            onRedirect?.()
          }}
        >
          {t('create-company.redirect-btn')}
        </Button>
      )}
    </Box>
  )
}

export default DialogOption
