import {makeStyles} from '@mui/styles'

export const useTableWrapperStyles = makeStyles(() => ({
  tableWrapper: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& > div': {
      borderTop: 'none',
    },
    '@media (min-width: 1600px)': {
      flexDirection: 'initial',
    },
  },
}))
