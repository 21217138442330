import {FormikErrors} from 'formik'

import {CompanyFormValues, ServiceGroupItem} from './types'

export const getServiceError = <T extends keyof ServiceGroupItem>(
  errors: FormikErrors<CompanyFormValues>,
  field: T,
) => {
  const services = Array.isArray(errors?.services) ? errors.services : []

  // eslint-disable-next-line no-restricted-syntax
  for (const service of services) {
    if (typeof service === 'string') {
      return service
    }

    if (typeof service === 'object' && service !== null && !Array.isArray(service)) {
      const error = service[field]
      if (error) {
        return error
      }

      const {serviceFromGroup} = service

      if (Array.isArray(serviceFromGroup)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const serviceGroup of serviceFromGroup) {
          if (typeof serviceGroup === 'object' && serviceGroup !== null) {
            const serviceGroupError = serviceGroup[field]
            if (serviceGroupError) {
              return serviceGroupError
            }
          }
        }
      }
    }
  }

  return undefined
}
