import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

const useServiceCardStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    padding: '24px 36px 24px 50px',
    borderRadius: '4px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: colors.white,
    position: 'relative',
  },
  orderContainer: {
    width: '35px',
    height: '100%',
    background: colors.gray60,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
  },
  orderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
  },
  orderInput: {
    margin: '0 5px',
    maxWidth: '28px',
    '& input': {
      padding: '4px 2px',
      backgroundColor: colors.white,
      fontSize: '14px',
      textAlign: 'center',
      borderRadius: '4px',
    },
  },
  orderIconTop: {
    transform: 'rotate(90deg)',
  },
  orderIconBottom: {
    transform: 'rotate(-90deg)',
  },
  cardWrapper: {
    display: 'grid',
    rowGap: '16px',
  },
  switchControlLabel: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    color: colors.black,
  },
  myFormControlLabelRoot: {
    marginBottom: 0,
    minHeight: 48,
    marginRight: 0,
  },
  switchBox: {
    display: 'flex',
    alignItems: 'baseline',
  },
  companyControlsRow: {
    display: 'grid',
    gap: 20,
    '& + &': {
      borderTop: `1px solid ${colors.gray60}`,
      marginTop: 25,
      paddingTop: 25,
    },
  },
  templateFilesReferenceBox: {
    display: 'grid',
    gap: 20,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  companyControlsCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 24,
    maxWidth: 'calc(50% - 37.5px)',
  },
  addNewItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 80,
    gap: 8,
    borderRadius: 4,
    border: `1px dashed ${colors.gray60}`,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 'normal',
  },
  employeeCard: {
    '& + &': {
      marginTop: 24,
    },
  },
  serviceCardDelete: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: colors.gray500,
  },
  companyHrsNotRequired: {
    justifyContent: 'center',
    textAlign: 'center',
    color: colors.gray350,
  },
  addHrButtonWrapper: {
    '& + &': {
      marginTop: 24,
    },
  },
  icon: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
  inputWrapper: {
    position: 'relative',
  },
  resetIcon: {
    position: 'absolute',
    right: 15,
    top: 2,
  },
}))

export default useServiceCardStyles
