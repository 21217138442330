import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import {Box, IconButton, ListItem} from '@mui/material'
import clsx from 'clsx'
import {Typography} from 'ui-lib'

import {useDocumentItemStyles} from './styles'

interface Control {
  Icon: React.ElementType
  onClick: () => void
  label?: string
  classes?: string
  canSee: boolean
}

interface DocumentItemProps extends React.HTMLAttributes<HTMLElement> {
  fileName: string
  controls?: Control[]
}

const DocumentItem: React.FC<DocumentItemProps> = ({fileName, controls, className, ...props}) => {
  const classes = useDocumentItemStyles()
  const {t} = useTranslation()

  const [hovered, setHovered] = useState(false)

  const maxLengthFileName = 100
  const formattedFileName =
    fileName.length > maxLengthFileName ? `${fileName.slice(0, maxLengthFileName)}...` : fileName

  return (
    <ListItem
      className={clsx(classes.document, className)}
      {...props}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box className={classes.nameBlock} data-test="file-name">
        <Typography className={classes.subName} variant="subtitle2">
          {t('upload-file.document-sub-name')}
        </Typography>

        <Typography variant="inherit">{formattedFileName}</Typography>
      </Box>

      {controls && (
        <Box className={clsx(classes.iconBox, {[classes.iconBoxVisible]: hovered})}>
          {controls?.map(({Icon, onClick, label, classes, canSee}) => {
            if (!canSee) return null
            return (
              <IconButton key={label} onClick={onClick} className={classes} aria-label={label} component="label">
                <Icon />
              </IconButton>
            )
          })}
          <IconButton disabled>
            <MoreVertIcon className={clsx(classes.dotsBox, classes.dotsHovered)} />
          </IconButton>
        </Box>
      )}

      <MoreVertIcon className={classes.dotsBox} />
    </ListItem>
  )
}

export default DocumentItem
