import get from 'lodash/get'

import {Errors} from 'constants/errors'
import {useErrors} from 'hooks/useErrors'
import {CompanyServicesOutDto, useCreateAdminCompanyMutation} from 'store/requests'

const CreateCompanySettings = () => {
  const handleErrors = useErrors()
  const [createCompanySettings, {isLoading}] = useCreateAdminCompanyMutation()

  const handleCreateCompanySettings = async (values: CompanyServicesOutDto) => {
    try {
      const {id} = await createCompanySettings(values).unwrap()
      return id
    } catch (error) {
      const errorCode = get(error, 'data.code') as unknown as Errors

      handleErrors(errorCode, 'notifications.bad-request')
      return undefined
    }
  }

  return [handleCreateCompanySettings, {isLoading}] as const
}

export default CreateCompanySettings
