const routes = {
  main: '/',
  admin: '/admin/companies',
  adminCompaniesList: '/admin/companies',
  adminServicesList: '/admin/services',
  detailsCompany: '/admin/companies/:id',
  editCompany: '/admin/companies/:id/edit',
  createCompany: '/admin/create-company',
  createService: '/admin/create-service',
  editService: '/admin/edit-service/:id',
  noAccess: '/no-access',
  notFound: '/404',
}

export default routes
