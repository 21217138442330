import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

export const useUploadingFile = makeStyles(() => ({
  documentList: {
    display: 'grid',
    rowGap: 16,
    padding: 0,
    margin: 0,
  },
  deleteBtn: {
    color: colors.gray500,
  },
  title: {
    marginBottom: 10,
    color: colors.black,
  },
  validateText: {
    marginTop: 10,
    color: colors.gray500,
  },
  spinner: {
    '&  div': {
      width: 'fit-content',
      margin: 'auto',
      position: 'initial',
    },
    '& span': {
      position: 'initial',
    },
  },
}))

export const useDocumentItemStyles = makeStyles(() => ({
  document: {
    position: 'relative',
    minHeight: 82,
    boxSizing: 'border-box',
    padding: '5px 5px 5px 15px',
    backgroundColor: colors.white,
    borderRadius: 4,
    boxShadow: `0 2px 4px 0 ${colors.gray300}`,
    border: `solid 1px ${colors.gray60}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
      backgroundColor: colors.gray50,
    },
  },
  nameBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  subName: {
    fontSize: 12,
    lineHeight: '16px',
    color: colors.gray350,
  },
  iconBox: {
    marginLeft: '10px',
    display: 'flex',
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s ease, visibility 0.3s ease',
    backgroundColor: colors.white,
    padding: 10,
    borderTopLeftRadius: '50px',
    borderBottomLeftRadius: '50px',
    position: 'absolute',
    right: 0,
    boxShadow: `-2px 5px 10px 0 ${colors.gray300}`,
  },
  iconBoxVisible: {
    opacity: 1,
    visibility: 'visible',
  },
  dotsBox: {
    color: colors.gray500,
    marginRight: 13,
  },
  dotsHovered: {
    color: colors.gray300,
    marginRight: 0,
  },
}))
