import React from 'react'
import {useTranslation} from 'react-i18next'

import {useField, useFormikContext} from 'formik'
import {Autocomplete, AutocompleteProps} from 'ui-lib'

export interface FormAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<Multiple, DisableClearable, FreeSolo>, 'value' | 'title'> {
  name: string
  onChange?: (e: React.ChangeEvent<unknown>, options: any) => void
}

const FormAutocomplete = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: FormAutocompleteProps<Multiple, DisableClearable, FreeSolo>,
) => {
  const {t} = useTranslation()
  const [{onChange, multiple, ...field}, meta] = useField(props.name)
  const {setFieldValue, setFieldTouched} = useFormikContext()

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<unknown>, options: any) => {
      setFieldValue(props.name, options)
      setFieldTouched(props.name, true)
    },
    [setFieldValue, setFieldTouched, props.name],
  )

  const selectProps: FormAutocompleteProps<Multiple, DisableClearable, FreeSolo> = {
    onChange: handleChange,
    ...field,
    ...props,
  }

  if (meta && meta.touched && meta.error) {
    selectProps.error = true
    selectProps.helperText = t(meta.error as any)
  }

  return <Autocomplete<Multiple, DisableClearable, FreeSolo> {...selectProps} noOptionsText={t('no-options')} />
}

export default FormAutocomplete
