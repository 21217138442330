import React, {useEffect} from 'react'
import {TranslationUA} from 'react-app-env'
import {Normalize, useTranslation} from 'react-i18next'

import {Box} from '@mui/material'
import {useField} from 'formik'
import {ContentSpinner, Typography} from 'ui-lib'

import Upload from 'components/Upload'
import {UploadProps} from 'components/Upload/Upload'
import FileList, {FileListProps} from 'components/UploadingFile/FileList'
import {useSnackbar} from 'hooks/useSnackbar'

import {validateSizeInPx} from './utils'
import {useUploadingFile} from './styles'

export type SizeInPx = {width: number; height: number}

export interface FileObject {
  file: File
  id: string
}

export interface DefaultFile {
  fileContents: string /* base64 string */
  contentType: string
  fileDownloadName: string
  enableRangeProcessing: boolean
}

interface UploadingFileProps extends Omit<UploadProps, 'showError' | 'disabled' | 'onChange'> {
  maxSizeInMBytes?: number
  error?: string
  title?: string
  validateText?: string
  defaultFiles?: FileObject[]
  isLoading?: boolean
  fileTypes?: string[]
  className?: string
  maxFilesLength?: number
  fileSizeInPx?: SizeInPx
  validateInPxMessage?: Normalize<TranslationUA>
  withStrongValidateSizeInPx?: boolean
  fileViewProps?: Omit<FileListProps, 'files' | 'handleDelete'>
}

export const UploadingFile: React.FC<UploadingFileProps> = ({
  maxSizeInMBytes = 10,
  accept = '.pdf,.jpeg,.jpg,.png',
  fileTypes = ['image/svg', 'image/svg+xml'],
  defaultFiles,
  isLoading,
  className,
  maxFilesLength = 1,
  inputName = 'logo',
  fileSizeInPx,
  validateInPxMessage,
  withStrongValidateSizeInPx,
  title,
  validateText,
  fileViewProps,
  ...uploadProps
}) => {
  const {t} = useTranslation()
  const enqueueSnackbar = useSnackbar()
  const [field, meta, helpers] = useField<FileObject[]>(inputName)

  const isMaxFilesLength = field.value?.length >= maxFilesLength
  const showError = meta.touched && meta.error

  const classes = useUploadingFile({})
  const maxLengthFileName = 20
  const maxSizeInBytes = maxSizeInMBytes * 1024 * 1024

  const validateFiles = async (fileArr: File[]): Promise<File[]> => {
    const newFiles = fileArr.slice(0, 10 - (field.value ?? []).length)

    if (newFiles.length < fileArr?.length) {
      enqueueSnackbar(t('upload-file.file-count-error'), {variant: 'error'})
    }

    const validationPromises = newFiles.map(async file => {
      const fileName = file.name.length > maxLengthFileName ? `${file.name.slice(0, maxLengthFileName)}...` : file.name

      if (!fileTypes.includes(file.type)) {
        enqueueSnackbar(t('upload-file.file-type-error', {fileName, fileTypes: accept.split(',').join('/')}), {
          variant: 'error',
        })
        return null
      }

      if (fileSizeInPx) {
        const isValidSizeInPx = await validateSizeInPx(file, fileSizeInPx, withStrongValidateSizeInPx)

        if (!isValidSizeInPx) {
          enqueueSnackbar(t((validateInPxMessage as any) || 'upload-file.file-size-not-valid'), {
            variant: 'error',
          })
          return null
        }
      }

      if (file?.size <= maxSizeInBytes) {
        return file
      }

      enqueueSnackbar(t('upload-file.file-size-error', {fileName, maxSizeInMBytes}), {variant: 'error'})
      return null
    })

    const validationResults = await Promise.all(validationPromises)
    const validFiles = validationResults.filter((file): file is File => file !== null)

    return validFiles.length ? validFiles : []
  }

  const setFiles = (fileObj: FileObject[]) => {
    helpers.setValue([...fileObj, ...(field.value ?? [])])
  }

  const handleUploaderChange = async (fileArr: File[]) => {
    const validFiles = await validateFiles(fileArr)

    if (validFiles.length > 0) {
      const fileObj = validFiles?.map(file => ({
        id: Math.floor(Math.random() * file.size).toString(),
        file,
      }))
      setFiles(fileObj)
    }
  }

  const handleDelete = (id: string) => {
    helpers.setValue(field.value.filter(file => file.id !== id))
  }

  useEffect(() => {
    if (defaultFiles?.length) {
      setFiles(defaultFiles)
    }
  }, [defaultFiles])

  if (isLoading) {
    return (
      <Box className={classes.spinner}>
        <ContentSpinner isLoading />
      </Box>
    )
  }

  return (
    <Box className={className}>
      {title && (
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
      )}

      <Upload
        onChange={handleUploaderChange}
        disabled={isMaxFilesLength}
        accept={accept}
        showError={showError}
        inputName={inputName}
        {...uploadProps}
      />

      {Boolean(field?.value?.length) && (
        <FileList handleDelete={handleDelete} files={field?.value} {...fileViewProps} />
      )}

      {validateText && !field?.value?.length && (
        <Typography variant="body2" className={classes.validateText}>
          {validateText}
        </Typography>
      )}
    </Box>
  )
}
