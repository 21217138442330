import React from 'react'
import {useTranslation} from 'react-i18next'

import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import Divider from '@mui/material/Divider'
import {StyledEngineProvider} from '@mui/material/styles'
import {commonDimensions, Typography, Button, Dialog, ThemeProvider} from 'ui-lib'

import useDialogComponentStyles from './styles'

type DialogComponentProps = {
  closeModal: (value: boolean) => void
  message?: string
}
const DialogComponent: React.FC<DialogComponentProps> = ({closeModal, message}) => {
  const {t} = useTranslation()
  const classes = useDialogComponentStyles()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme="light">
        <Dialog
          open
          onClose={() => closeModal(true)}
          data-test="router-confirmation-dialog"
          title={t('router-confirmation.title')}
          paperWidth={commonDimensions.dialogPaperWidth}
        >
          <Box mx={3} mb={3}>
            <Typography data-test="dialog-sub-title" variant="h2" className={classes.subTitle}>
              {message || t('router-confirmation.you-are-going-to-leave')}
            </Typography>
            <Typography data-test="dialog-sub-title-extra" className={classes.description}>
              {t('router-confirmation.changes-will-be-lost')}
            </Typography>
            <Typography data-test="dialog-confirm-your-action" className={classes.description}>
              {t('common-labels.confirm-your-action')}
            </Typography>
          </Box>
          <Divider />
          <DialogActions classes={{root: classes.dialogButtonsWrapper}}>
            <Button onClick={() => closeModal(false)} type="default-secondary-yellow" data-test="dialog-cancel-btn">
              {t('router-confirmation.back')}
            </Button>
            <Button onClick={() => closeModal(true)} type="default-primary-yellow" data-test="dialog-confirm-btn">
              {t('router-confirmation.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default DialogComponent
