import React from 'react'
import {useTranslation} from 'react-i18next'

import {Tooltip} from '@mui/material'
import ButtonBase from '@mui/material/ButtonBase'
import clsx from 'clsx'
import {Typography} from 'ui-lib'

import {useDialogOptionsStyles} from './styles'

export interface DialogOptionProps {
  onClick?: () => void
  selected?: boolean
  selectedDisabled?: boolean
  value: {name: string; englishName: string}
}

const DialogOption: React.FC<DialogOptionProps> = ({value, onClick, selected, selectedDisabled}) => {
  const classes = useDialogOptionsStyles()
  const {t} = useTranslation()

  return (
    <Tooltip
      onClick={!selectedDisabled ? onClick : undefined}
      className={clsx(selectedDisabled && classes.selectedDisabled, classes.container, selected && classes.selected)}
      title={selectedDisabled ? t('create-company.is-service-selected') : ''}
    >
      <ButtonBase disabled={selectedDisabled} data-test="service-btn" classes={{root: clsx(classes.buttonBaseRoot)}}>
        {value.name && (
          <Typography variant="subtitle2" className={classes.mainText}>
            {value.name}
          </Typography>
        )}
        {value.englishName && (
          <Typography variant="subtitle2" className={classes.text}>
            {value.englishName}
          </Typography>
        )}
      </ButtonBase>
    </Tooltip>
  )
}

export default DialogOption
