import React, {FC} from 'react'

import {Service} from 'pages/Company/CompaniesList/types'

import HolidayAccessItem from './HolidayAccessItem'

type ServicesAccessProps = {
  services: Service[]
}

const ServicesAccess: FC<ServicesAccessProps> = ({services = []}) => {
  return (
    <>
      {services.map(({id, isActive, hrNames, logo}) => (
        <HolidayAccessItem key={id} hrNames={hrNames} logo={logo} isActive={isActive} />
      ))}
    </>
  )
}

export default ServicesAccess
