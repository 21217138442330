import {useTranslation} from 'react-i18next'
import {generatePath, useHistory} from 'react-router-dom'

import get from 'lodash/get'

import {Errors} from 'constants/errors'
import {useErrors} from 'hooks/useErrors'
import {useSnackbar} from 'hooks/useSnackbar'
import routes from 'routes'
import {useUpdateCompanyLogoMutation} from 'store/requests'
import {Id} from 'types'

const UpdateCompanyLogo = () => {
  const {t} = useTranslation()
  const handleErrors = useErrors()
  const enqueueSnackbar = useSnackbar()
  const [updateLogo, {isLoading}] = useUpdateCompanyLogoMutation()
  const history = useHistory()

  const handleUpdateLogo = async (id: Id, data: FormData) => {
    try {
      await updateLogo({id, data}).unwrap()
      enqueueSnackbar(t('notifications.admin-company-create-success'), {variant: 'success'})
      history.push(generatePath(routes.adminCompaniesList, {id}))

      return id
    } catch (error) {
      const errorCode = get(error, 'data.code') as unknown as Errors
      const isErrorCodeValidation = errorCode === Errors.VALIDATION_ERROR
      handleErrors(
        errorCode,
        `notifications.${isErrorCodeValidation ? 'file-for-logo-does-not-fit-size' : 'bad-request'}`,
      )
      return undefined
    }
  }

  return [handleUpdateLogo, {isLoading}] as const
}

export default UpdateCompanyLogo
