import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useHistory} from 'react-router-dom'

import ArchiveIcon from '@mui/icons-material/ArchiveOutlined'
import Box from '@mui/material/Box'
import {Column} from 'ui-lib'

import OverflowTip from 'components/OverflowTip'
import TableLink from 'components/TableLink'
import useQueryParams from 'hooks/useQueryParams'
import {useTableStyles} from 'pages/Company/CompaniesList/ListStyles'
import routes from 'routes'
import {formatDate} from 'utils/date'

import ServicesAccess from './components/ServicesAccess'
import {CompanyItem} from './types'

const RenderRows = (id: number | string, rows: {value: string; dataTest: string}[]) => {
  return (
    <>
      {rows.map(({value, dataTest}, index) => {
        const key = `${id}_${value}_${index}`

        return (
          <Box data-test={dataTest} key={key}>
            {value}
          </Box>
        )
      })}
    </>
  )
}

const ConfigColumns = () => {
  const {t} = useTranslation()
  const classes = useTableStyles({})
  const [queryParams] = useQueryParams()
  const history = useHistory()

  const columns: Column<CompanyItem>[] = [
    {
      width: '1%',
      id: 'id',
      label: t('administration.column-id'),
      className: classes.idCell,
      sortable: true,
      'data-test': 'admin-company-id',
      render: (id, row) => {
        const pathname = generatePath(routes.editCompany, {id: row.id})
        const state = {from: queryParams, pathname: history.location.pathname}
        return <TableLink to={{pathname, state}}>{id}</TableLink>
      },
    },
    {
      width: '4%',
      id: 'country',
      label: t('administration.country'),
      'data-test': 'admin-company-country',
    },
    {
      width: '20%',
      id: 'companyName',
      sortable: true,
      label: t('administration.column-company'),
      render: companyName => <OverflowTip text={companyName} maxLine={2} />,
      'data-test': 'admin-company-company',
    },
    {
      width: '70%',
      id: 'services',
      label: t('administration.column-access'),
      render: services => <ServicesAccess services={services} />,
      'data-test': 'admin-company-access',
    },
    {
      width: '5%',
      id: 'updatedAt',
      sortable: true,
      label: t('administration.column-updated-at'),
      render: (_, {updatedAt, id}) => (
        <Box className={classes.colBlock}>
          {RenderRows(id, [
            {value: formatDate(updatedAt), dataTest: 'admin-company-updated-at-date'},
            {value: formatDate(updatedAt, 'HH:mm'), dataTest: 'admin-company-updated-at-time'},
          ])}
        </Box>
      ),
      'data-test': 'admin-company-updated-at',
    },
    {
      width: '1%',
      id: 'isArchived',
      label: '',
      render: isArchived => (isArchived ? <ArchiveIcon /> : null),
      'data-test': 'admin-company-is-archived',
    },
  ]
  return columns
}

export default ConfigColumns
