import axios, {CancelTokenSource} from 'axios'

import {cancelMessage} from 'constants/errors'
import {maxResultItemsCount, minLettersCount} from 'constants/search'
import {Unit, Employee} from 'types'

import req from './axiosSetting'

const resource = 'workflow/search'

let cancelToken: CancelTokenSource | null = null

const getEmployees = (query: string, isLimitedByHrsCompanies?: boolean): Promise<Awaited<Employee[]>> => {
  if (cancelToken) {
    cancelToken.cancel(cancelMessage)
  }
  cancelToken = axios.CancelToken.source()

  if (query.length < minLettersCount) return Promise.resolve([])

  return req({
    url: isLimitedByHrsCompanies ? `${resource}/companies/employees` : `${resource}/employees`,
    params: {
      query,
      limit: maxResultItemsCount,
      offset: 0,
    },
    additionalData: {cancelToken: cancelToken.token},
  }).then(res => res.data.items)
}

const getAdminEmployees = (query: string): Promise<Awaited<Employee[]>> => {
  if (cancelToken) {
    cancelToken.cancel(cancelMessage)
  }
  cancelToken = axios.CancelToken.source()

  if (query.length < minLettersCount) return Promise.resolve([])

  return req({
    url: `${resource}/employees`,
    params: {
      query,
      limit: maxResultItemsCount,
      offset: 0,
    },
    additionalData: {cancelToken: cancelToken.token},
  }).then(res => res.data.items)
}

export type GetCompaniesDto = Unit[]

const getCompanies = (query: string): Promise<GetCompaniesDto> => {
  if (cancelToken) {
    cancelToken.cancel(cancelMessage)
  }
  cancelToken = axios.CancelToken.source()

  if (query.length < minLettersCount) return Promise.resolve([])

  return req({
    url: `${resource}/companies`,
    params: {
      query,
    },
    additionalData: {cancelToken: cancelToken.token},
  }).then(res => res.data)
}

const getAdminCompanies = (query: string): Promise<GetCompaniesDto> => {
  if (cancelToken) {
    cancelToken.cancel(cancelMessage)
  }
  cancelToken = axios.CancelToken.source()

  if (query.length < minLettersCount) return Promise.resolve([])

  return req({
    url: 'workflow/search/companies',
    params: {
      query,
    },
    additionalData: {cancelToken: cancelToken.token},
  }).then(res => res.data)
}

export default {getEmployees, getAdminEmployees, getCompanies, getAdminCompanies}
