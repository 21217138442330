import get from 'lodash/get'

import {Errors} from 'constants/errors'
import {useErrors} from 'hooks/useErrors'
import {useUpdateCompanyTemplateMutation} from 'store/requests'
import {Id} from 'types'

const UpdateCompanyTemplate = () => {
  const handleErrors = useErrors()
  const [updateTemplate, {isLoading}] = useUpdateCompanyTemplateMutation()

  const handleUpdateTemplate = async (id: Id, serviceId: Id, data: FormData) => {
    try {
      await updateTemplate({id, serviceId, data}).unwrap()
    } catch (error) {
      const errorCode = get(error, 'data.code') as unknown as Errors

      handleErrors(errorCode, 'notifications.bad-request')
    }
  }

  return [handleUpdateTemplate, {isLoading}] as const
}

export default UpdateCompanyTemplate
