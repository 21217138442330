import React from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'

import {Box} from '@mui/material'
import {Typography} from 'ui-lib'

import {ReactComponent as NoAccessSvg} from 'assets/noAccess.svg'

import useStyles from './styles'

const NoAccess = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Helmet title={t('no-access-page.title')} />
      <Box className={classes.wrapper}>
        <NoAccessSvg />
        <Typography variant="h2" data-test="no-access-title">
          {t('no-access-page.title')}
        </Typography>
        <Typography variant="subtitle2" color="secondary" data-test="no-access-subtitle">
          {t('no-access-page.description')}
        </Typography>
      </Box>
    </>
  )
}

export default NoAccess
