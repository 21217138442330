import {get, assign} from 'lodash'

const convertQsParamsToBooleans = (qsParams: object) => {
  const convertedQsParams = {}
  Object.keys(qsParams).forEach(key => {
    const value = get(qsParams, key)
    const shouldConvertToBoolean = value === 'true' || value === 'false'
    assign(convertedQsParams, {[key]: shouldConvertToBoolean ? value === 'true' : value})
  })
  return convertedQsParams
}

export default convertQsParamsToBooleans
