import get from 'lodash/get'

import {Errors} from 'constants/errors'

export const isErrorWithMessage = (error: unknown, message: string): boolean => {
  if (error && typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
    return error.message.includes(message)
  }
  return false
}

export const isStatusNotFound = (error: unknown) => {
  if (error && typeof error === 'object' && 'status' in error) {
    return error?.status === 404
  }
  return false
}

export const isBadRequest = (error: unknown) => {
  if (error && typeof error === 'object' && 'status' in error) {
    return error?.status === 400
  }
  return false
}

export const getErrorCode = (err: unknown) => {
  if (Array.isArray(get(err, 'data'))) {
    return Errors.ENTRY_NOT_FOUND
  }
  return get(err, 'data.code') as unknown as Errors
}
