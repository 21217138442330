import {makeStyles} from '@mui/styles'

const useExistingCompanyDialogStyles = makeStyles(() => ({
  dialogButtonsWrapper: {
    padding: 24,
    gap: 24,
  },
  subTitle: {
    lineHeight: 'inherit',
  },
  description: {
    marginTop: 16,
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 'normal',
  },
}))

export default useExistingCompanyDialogStyles
