import perPageParams from 'constants/pagination'

/**
 * @description Adapts pagination params to offset and limit
 * @param params
 * @returns
 * @example
 * paginationAdapter({page: 1, limit: 10}) // {offset: 0, limit: 10}
 * paginationAdapter({}) // {offset: 0, limit: 10}
 * paginationAdapter({a: 'a', b: 'b'}) // {a: 'a', b: 'b', offset: 0, limit: 10}
 * paginationAdapter({page: 2, limit: 20, a: 'a', b: 'b'}) // {a: 'a', b: 'b', offset: 20, limit: 20}
 */
export const paginationAdapter = <T extends Record<string, any>>(
  params?: T,
): Omit<T, 'page'> & {
  offset: number
  limit: number
} => {
  const {page, limit, ...rest} = params || {
    page: 1,
    limit: perPageParams[0],
  }

  const parsedPage = Number(page ?? 1)
  const parsedLimit = Number(limit ?? perPageParams[0])

  return {
    ...rest,
    limit: parsedLimit,
    offset: (parsedPage - 1) * parsedLimit,
  } as Omit<T, 'page'> & {
    offset: number
    limit: number
  }
}
