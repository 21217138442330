import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {Box} from '@mui/material'
import {ContentSpinner} from 'ui-lib'

import {adaptServiceData} from '../adapters/createServiceAdapter'
import {requestFormDataAdapter} from '../adapters/requestFormDataAdapter'
import ServiceForm from '../ServiceForm'
import {ServiceFormValues} from '../ServiceForm/types'

import routes from 'routes'

import useCreateService from './hooks/useCreateService'

const CreateService = () => {
  const {t} = useTranslation()
  const [createService, {isLoading}] = useCreateService()
  const history = useHistory()

  const handleSubmit = async (values: ServiceFormValues) => {
    const adaptedData = adaptServiceData(values)
    const adaptedToFormData = requestFormDataAdapter(adaptedData)

    await createService(adaptedToFormData)
  }

  const goBack = () => {
    history.push(routes.adminServicesList)
  }

  return (
    <Box sx={{position: 'relative'}}>
      <ContentSpinner isLoading={isLoading} alphaBackground={0.7} viewPortCentered>
        <ServiceForm goBack={goBack} title={t('create-service.form-title')} onSubmit={handleSubmit} />
      </ContentSpinner>
    </Box>
  )
}

export default CreateService
