import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import get from 'lodash/get'

import {Errors} from 'constants/errors'
import {useErrors} from 'hooks/useErrors'
import {useSnackbar} from 'hooks/useSnackbar'
import routes from 'routes'
import {useEditServiceMutation} from 'store/services'

const UseEditService = () => {
  const {t} = useTranslation()
  const handleErrors = useErrors()
  const enqueueSnackbar = useSnackbar()
  const history = useHistory()
  const [editService, {isLoading}] = useEditServiceMutation()

  const handleEditService = async (values: FormData) => {
    try {
      await editService(values).unwrap()
      enqueueSnackbar(t('notifications.service-edit-success'), {variant: 'success'})

      history.push(routes.adminServicesList)
    } catch (error) {
      const errorCode = get(error, 'data.code') as unknown as Errors

      handleErrors(errorCode, 'notifications.bad-request')
    }
  }

  return [handleEditService, {isLoading}] as const
}

export default UseEditService
